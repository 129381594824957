<template>
	<div>
		
		<div class="container md:px-4 mx-auto mt-12 mb-6">
			<div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
				
				<div class="flex justify-between">
					<div class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Admin Snowflake Gallery</div>
					<div v-if="$wallet.methods.isConnected()==true">
						<button @click="onRefresh" class="font-bold text-gray-400 text-sm bg-black rounded-full px-3 py-1 bg-opacity-10 hover:bg-opacity-20">
							<div class="flex items-center justify-center">
								<span>REFRESH</span> 
								<i class="fa fa-sync-alt ml-1"/>
							</div>
						</button>
					</div>
				</div>

				<div class="text-base mb-2">
					This displays the entire collection of 10,000 snowflakes as admin for image checking.
				</div>

				<div v-if="$wallet.state.address==null" class="px-3 py-2 bg-black bg-opacity-10 rounded-full mt-12 text-center">
					Connect Your Wallet
				</div>

			</div>
		</div>


		<!-- PAGINATOR -->
		<paginator v-if="totalPages>1" class="mt-12 mb-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
		<!-- PAGINATOR -->


		<!-- GALLERY -->
		<div class="container md:px-4 mx-auto">
			<div v-if="snowflakes.length>0" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-5 mx-2 md:mx-0">
				<div v-for="n of snowflakes" v-bind:key="n.tokenId">
					<div class="w-full lg:h-full p-3 md:p-5 shadow-xl rounded-lg border-4 card-bg">
						
						<div class="w-full mb-3">
							<img v-if="n.data==null" src="/images/snowflake-empty.svg" class="rounded-lg"/>
							<img v-else @click="imageClicked(n.data.image)" :src="n.data.image" class="cursor-pointer rounded-lg"/>
						</div>

						<div class="mt-2 flex justify-between bg-black bg-opacity-20 rounded-lg w-full px-2 py-1 md:px-3 md:py-2 text-sm">
							<div>
								<span class="hidden xl:inline-block font-bold">Snowflake&nbsp;</span>
								<span class="text-gray-400 text-sm">#</span>
								<span class="font-bold">{{n.tokenId}}</span>
							</div>
							<div v-if="n.data!=null" class="text-right">
								<span v-if="n.data.claimed==null" class="text-gray-400" style="padding-top:2px;padding-bottom:2px">NONE</span>
								<span v-else-if="n.data.owner!=null && !$common.addressEquals(n.data.owner,$wallet.state.address)" style="padding-top:2px;padding-bottom:2px" v-html="$common.shortenAddressHtml(n.data.owner)"/>
								<span v-else class="text-yellow-400" style="padding-top:2px;padding-bottom:2px">YOU</span>
							</div>
						</div>
							
					</div>
				</div>
			</div>
		</div>
		<!-- GALLERY -->

		
		<!-- PAGINATOR -->
		<paginator v-if="totalPages>1" class="mt-12 mb-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
		<!-- PAGINATOR -->

	</div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import { ethers } from "ethers"
  import Paginator from "../components/Paginator"

  const PER_PAGE = 100

  export default {
    
    components: {Paginator},

    setup() {
      const currentPage = ref(0)
      const totalPages = ref(0)
      const snowflakes = ref([])
      const maxSupply = ref(0)
      const isRefreshing = ref(false)

      return { currentPage, totalPages, snowflakes, maxSupply, isRefreshing };
    },

    async created() {
      this.$emitter.on('wallet-connect', this.refreshData)
      this.$emitter.on('wallet-disconnect', this.clearData)
      this.$emitter.on('refresh-data', this.refreshData)
    },

    async mounted() {
      await this.refreshData();
    },
    
    async beforeUnmount() {
      this.$emitter.off('wallet-connect')
      this.$emitter.off('wallet-disconnect')
      this.$emitter.off('refresh-data')
    },

    methods: {
      
      async clearData() {
        this.maxSupply = 0
        this.isRefreshing = false
        this.snowflakes.length = 0
      },

      async refreshData() {
        if(this.$wallet.state.currentChain==null || this.isRefreshing==true) return;
        this.isRefreshing = true
        
        let c = this.$wallet.state.currentChain.contracts.SnowflakeCollection.exec
        this.maxSupply = parseInt(await c.maxSupply())

        //Get thoughts, sort while we load
        let startIndex = (this.currentPage * PER_PAGE) + 1
        let endIndex = Math.min(this.maxSupply,startIndex+PER_PAGE)
        this.totalPages = Math.ceil(this.maxSupply/PER_PAGE)
        let arr = []
        for(let i=startIndex;i<endIndex;i++) arr.push({tokenId:i,data:null})
        this.snowflakes = arr

        // Get snowflake and owner later
        for(let snowflake of this.snowflakes) {
          this.$wallet.methods.adminGetSnowflake(snowflake.tokenId).then(data=>{
            snowflake.data = data
            if(data.owner==null && data.claimed==true) c.ownerOf(snowflake.tokenId).then(owner=>snowflake.data.owner=owner)
          })
        }

        this.isRefreshing = false
      },

      updatePage(p) {
        this.currentPage = p;
        this.refreshData()
      },

      imageClicked(n) {
        this.$emitter.emit('image-modal',{src:n})
      },

      onRefresh() {
        this.$emitter.emit('notification',{variant:'success',message:"Refresh started."})
        this.refreshData()
      },

    },

    computed: {
    }

  }
</script>
