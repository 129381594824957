import {reactive} from 'vue'

const state = reactive({
  FB_URL: "https://www.facebook.com/snowflakesnft",
  OPENSEA_URL: "https://opensea.io/collection/snowflakecollection",
	RARIBLE_URL: "https://rarible.com/snowflakecollection",
  TWITTER_URL: "https://twitter.com/snowflakesnft",
  CONTACT_EMAIL: "snowflakesnft@gmail.com",
  DISCORD_URL: "https://discord.gg/5wMwwJsycb"
});

const methods = {
}

export default {
  state,
  methods
}
