<template>
	<div>

		<div class="container md:px-4 mx-auto mt-12 mb-6">
			<div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
				
				<div class="flex justify-between">
					<div class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Snowflake Club</div>
					<div v-if="$wallet.methods.isConnected()==true">
						<button @click="onRefresh" class="font-bold text-gray-400 text-sm bg-black rounded-full px-3 py-1 bg-opacity-10 hover:bg-opacity-20">
							<div class="flex items-center justify-center">
								<span>REFRESH</span> 
								<i class="fa fa-sync-alt ml-1"/>
							</div>
						</button>
					</div>
				</div>

				<div class="text-base">
					Snowflake Club is a Web 3.0 experiment in decentralized public messaging.  
					All owners in this exclusive club and can express their thoughts publicly through each of their Snowflakes.
					They can also edit their message at anytime.
					You can post marketing materials, or get some attention for the projects you are working on.
					There are reasonable restrictions, please see the <router-link :to="{name:'About'}" class="underline">About</router-link> page for more information.
					The messages are sorted by the 3 rarities (Divinity, Revolution and Wisdom) then it is sorted by the date posted.
				</div>

				<div v-if="$wallet.state.address==null" class="px-3 py-2 bg-black bg-opacity-10 rounded-full mt-12 text-center">
					Connect Your Wallet
				</div>
				<div v-else-if="thoughts.length==0" class="px-3 py-2 bg-black bg-opacity-10 rounded-full mt-12 text-center">
					No Thoughts Yet
				</div>

			</div>
		</div>


		<!-- PAGINATOR -->
		<paginator v-if="totalPages>1" class="mt-12 mb-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
		<!-- PAGINATOR -->


		<!-- THOUGHTS -->
		<div class="container md:px-4 mx-auto">
			<div v-if="thoughts.length>0" class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-5">
				<div v-for="(n,i) of thoughts" v-bind:key="n.index">
					<thought-view :n="n" :i="i" :message-count="messageCount"/>
				</div>
			</div>
		</div>
		<!-- THOUGHTS -->


		<!-- PAGINATOR -->
		<paginator v-if="totalPages>1" class="my-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
		<!-- PAGINATOR -->
  
	</div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import { ethers } from "ethers"
  import Paginator from "../components/Paginator"
  import ThoughtView from '../components/ThoughtView.vue'

  const PER_PAGE = 12

  export default {

    components: {Paginator,ThoughtView},

    setup() {
      const currentPage = ref(0)
      const totalPages = ref(0)
      const thoughts = ref([])
      const messageCount = ref(0)
      const isRefreshing = ref(false)

      return { thoughts, messageCount, currentPage, totalPages, isRefreshing };
    },

    async created() {
      this.$emitter.on('wallet-connect', this.refreshData)
      this.$emitter.on('wallet-disconnect', this.clearData)
      this.$emitter.on('refresh-data', this.refreshData)
      this.$emitter.on('thought-update', async (tokenId)=>{
        let thought = this.thoughts.find(n=>n.data.tokenId==tokenId)
        if(thought!=null) {
          let updatedThought = await this.$wallet.methods.getThought(tokenId)
          thought.data.date = updatedThought.date
          thought.data.message = updatedThought.message
          
          // Sort all thoughts
          this.thoughts = this.thoughts.filter(n=>n.data.ban==false).sort((a,b)=>b.data.rarity-a.data.rarity || b.data.date.diff(a.data.date))
        }
      })
    },

    async mounted() {
      await this.refreshData();
    },
    
    async beforeUnmount() {
      this.$emitter.off('wallet-connect')
      this.$emitter.off('wallet-disconnect')
      this.$emitter.off('refresh-data')
      this.$emitter.off('thought-update')
    },

    methods: {
      
      async clearData() {
        this.currentPage = 0
        this.totalPages = 0
        this.messageCount = 0
        this.thoughts.length = 0
      },

      async refreshData() {
        if(this.$wallet.state.currentChain==null || this.isRefreshing==true) return;
        this.isRefreshing = true
        
        let c = this.$wallet.state.currentChain.contracts.SnowflakeThoughts.exec
        this.messageCount = parseInt(await c.messageCount())
        
        // Get thoughts, sort while we load
        let arr = []
        for(let i=0;i<this.messageCount;i++) arr.push({index:i,data:null})
        this.thoughts = arr;

        // Get thought and snowflake
        for(let n of this.thoughts) {
          await this.$wallet.methods.getThoughtByIndex(n.index).then(thought=>{
            n.data = thought
            if(this.thoughts.filter(m=>m.data!=null).length==this.thoughts.length) this.loadSnowflakes() // If the last thought loaded, we can no sort and get all images
          })
        }

        this.isRefreshing = false
      },

      loadSnowflakes() {
        // Sort all thoughts
        this.thoughts = this.thoughts.filter(n=>n.data.ban==false && n.data.message.length>0).sort((a,b)=>b.data.rarity-a.data.rarity || b.data.date.diff(a.data.date))

        // Set pages
        this.totalPages = Math.ceil(this.thoughts.length/PER_PAGE)
        if(this.currentPage>=this.totalPages) this.currentPage = this.totalPages - 1

        // Get thought images now
        for(let n of this.thoughts) {
          if(n.data.image==null) this.$wallet.methods.getSnowflakeImage(n.data.tokenId).then(image=>n.data.image=image)
        }
      },

      onRefresh() {
        this.$emitter.emit('notification',{variant:'success',message:"Refresh started."})
        this.clearData()
        this.refreshData()
      },

      updatePage(p) {
        this.currentPage = p;
        this.refreshData()
      },

    },

    computed: {
    }

  }
</script>
