<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open=false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md w-4/5 sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center">
                <img :src="image" class="h-16 w-16 rounded-full"/>
              </div>
              <div class="mt-2 sm:mt-5">
                <DialogTitle class="text-xl leading-6 font-medium text-gray-900 text-center ">
                  Your Thoughts #{{tokenId}}
                </DialogTitle>
                <div class="mt-4">
                  <div class="flex justify-between">
                    <label for="message" class="block text-sm font-medium text-gray-700">
                      <span v-if="messageError!=null" class="text-red-500">{{messageError}}</span>
                      <span v-else>Message</span>
                    </label>
                    <span class="text-sm text-gray-500">
                      <span :class="messageError!=null?'text-red-500':''">{{message.length}}</span>
                      / 
                      {{MAX_CHARS}}</span>
                  </div>
                  <div class="mt-2 border-b border-gray-300 focus-within:border-indigo-400 outline-none">
                    <textarea name="message" id="message" class="block w-full border-0 border-b border-transparent bg-gray-100 rounded-t-lg focus:border-indigo-500 focus:ring-0 p-3 outline-none text-gray-700 h-24" placeholder="Your message to the world ..." v-model="message" @keyup="onKeyUp"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full mt-3 text-sm px-1">
              <span class="text-gray-400">Although this is free speech uncensored, we reserve to the right to remove anything that doesn't comply with standard US FCC rules.  Only text is allowed, no JS or HTML code.</span>
            </div>
            <div class="mt-8 sm:flex">
              <div class="w-full mb-2 sm:mb-0 sm:w-1/2 sm:mr-1">
                <button :class="messageError!=null?'bg-gray-500 border-gray-600':'bg-indigo-500 hover:bg-indigo-400 border-indigo-600'" class="w-full font-bold border-2 text-white px-3 py-1 rounded-full focus:outline-none focus:ring focus:border-indigo-300" :disabled="messageError!=null" @click="onButtonSubmit">Submit</button>
              </div>
              <div class="w-full sm:w-1/2 sm:ml-1">
                <button class="w-full font-bold bg-red-500 hover:bg-red-400 border-2 border-red-600 text-white px-3 py-1 rounded-full focus:outline-none focus:ring focus:border-red-300" @click="onCancel">Cancel</button>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
  // https://tailwindui.com/components/application-ui/overlays/modals

  import {ref} from 'vue';
  import { ethers } from "ethers"

  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionChild, 
    TransitionRoot
  } from "@headlessui/vue";

  export default {
    
    components: {
      Dialog, DialogOverlay, DialogTitle, DialogDescription, TransitionChild, TransitionRoot
    },

    setup() {
      const open = ref(false)
      const thought = ref(null)
      const message = ref("")
      const image = ref(null)
      const messageError = ref(null)
      const hash = ref(null)
      const MAX_CHARS = ref(150)
      return { open, thought, message, image, messageError, hash, MAX_CHARS }
    },

    mounted() {
      this.$emitter.on('thought-input-modal', async (params) => {
        this.open = true
        this.tokenId = params.tokenId
        this.thought = params.thought
        this.message = params.thought!=null?params.thought.message:''
        this.image = await this.$wallet.methods.getSnowflakeImage(params.tokenId)
        this.messageError = null
        this.hash = null
      })
    },

    methods: {

      async onButtonSubmit() {
        this.open = false

        // Same thought
        if(this.thought!=null) {
          //console.log(JSON.stringify(this.thought))
          if(this.thought.message == this.message) {
            this.$emitter.emit('notification', {variant:'warning',message:`No changes were made.`})
            return
          }
        }

        try {
          let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
          let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeThoughts.address, this.$wallet.state.abis.SnowflakeThoughts, provider.getSigner())  
          let tx = await snowSigned.send(this.tokenId, this.message, {from:this.$wallet.state.address})
          this.hash = tx.hash
          if(this.thought!=null) this.$wallet.methods.clearThought(this.tokenId) //Clear thought so it reloads

          await snowSigned.on("MessageEvent",(owner,tokenId,message,tx)=>{
            if(tx.transactionHash!=this.hash) return
            this.hash = null
            this.$wallet.methods.clearThought(tokenId)

            if(this.thought!=null) this.$emitter.emit('thought-update',tokenId) 
            else this.$emitter.emit('refresh-data')

            this.$emitter.emit('notification', {variant:'success',message:`Your thought posted successfully.`})
          })

          this.$emitter.emit('ok-modal', {title:"Submission Started",variant:'success',message:`<p class="text-lg">Your thought post has started.</p><br/>Check the progress by visiting <a class="underline" target="_blank" href="${this.$wallet.state.currentChain.etherscan_tx_url}${this.hash}">Etherscan</a> or wait here until it is complete.  It may take a few minutes to complete.`})
        } 
        catch(err) {
          let message = this.$wallet.methods.xlatWeb3Error(err)
          this.$emitter.emit('ok-modal', {title:"Wallet Error",variant:'danger',message})
          console.log(message)
          //this.open = false;
        }
      },

      onCancel() {
        this.open = false;
      },

      onKeyUp() {
        if(this.message.length > this.MAX_CHARS) this.messageError = 'Too many characters'
        else this.messageError = null
      }

    },

    computed: {
    }

  }

</script>
