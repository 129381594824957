export default {
  chainId: '0x1',
  name: "Ethereum Mainnet",
  etherscan_address_url: "https://etherscan.io/address/",
  etherscan_tx_url: "https://etherscan.io/tx/",
  image: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png", //Native coin image (use in login)
  contracts: {
    SnowflakeCollection: {
      exec: null,
      address: '0xd8a90ab39bfff3e8ecf3cae61cc504e92fbf4d7c',
    },
    SnowflakeThoughts: {
      exec: null,
      address: '0x6e828b56de98800025e670467f728d8c51488ff2',
    }
  }  
}