<template>
	<div class="w-full lg:h-full flex p-3 md:p-5 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
		
		<div class="w-2/6 mr-3" :title="'#'+(i+1)">
			<img v-if="n.data!=null && n.data.image!=null" @click="imageClicked(n.data.image)" :src="n.data.image" class="cursor-pointer rounded-lg"/>
			<img v-else src="/images/snowflake-empty.svg" class="rounded-lg"/>
		</div>

		<div v-if="n.data!=null" class="bg-black bg-opacity-20 rounded-lg w-full px-3 py-2">
		
			<div class="mb-3 flex justify-between">

				<div>
					<span class="text-md font-bold">Snowflake #{{n.data.tokenId}}</span>
					<div class="text-sm mt-1">
						<span class="text-gray-300">by</span> 
						<span v-if="!$common.addressEquals(n.data.sender,$wallet.state.address)" class="bg-black bg-opacity-30 mx-1 px-2 py-0 rounded-lg" style="padding-top:2px;padding-bottom:2px" v-html="$common.shortenAddressHtml(n.data.sender)"/>
						<span v-else class="bg-black bg-opacity-30 mx-1 px-2 py-0 text-yellow-400 rounded-lg" style="padding-top:2px;padding-bottom:2px">YOU</span>
						<span class="text-gray-300">on</span> <span class="bg-black bg-opacity-30 mx-1 px-2 py-0 rounded-lg" style="padding-top:2px;padding-bottom:2px" v-html="$common.formatDateHtml(n.data.date)"/>
					</div>
				</div>

				<div class="font-bold text-gray-400 text-sm">
					<div class="flex items-center justify-center mt-1 text-sm">
						<span v-if="messageCount!=null">{{i+1}} / {{messageCount}}</span> 
						<div v-if="$common.addressEquals(n.data.sender,$wallet.state.address)" style="padding-bottom:3px" class="ml-2 cursor-pointer" @click="onEdit(n)">
							<i class="fa fa-edit text-yellow-500"/>
						</div>
					</div>
				</div>

			</div>

			<div class="text-left justify-start h-full text-white pb-1 font-bold text-lg md:text-xl">
				<span v-html="n.data.message"/>
			</div>
			
		</div>

	</div>
</template>



<script>
  export default {

		props: {
			n: Object,
			i: Number,
			messageCount: Number
		},
    
    setup() {
    },

    async created() {
    },

    async mounted() {
    },
    
    methods: {

      onEdit(n) {
        if(n.data==null) return
        if(!this.$common.addressEquals(n.data.sender,this.$wallet.state.address)) return
        this.$emitter.emit('thought-input-modal',{tokenId:n.data.tokenId,thought:n.data})
      },

      imageClicked(n) {
        this.$emitter.emit('image-modal',{src:n})
      }

    },

    computed: {
    }

  }
</script>
