<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open=false">
      <div class="min-h-screen text-center block p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity"/>
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 translate-y-0 scale-95">
          <div class="inline-block transform transition-all align-middle w-full" @click="open=false">
            <div class="flex justify-center cursor-pointer">
              <img :src="src" class="border-black border-4 border-opacity-20 rounded-2xl shadow-2xl" style="max-height:92vh;max-width:92vw"/>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
  // https://tailwindui.com/components/application-ui/overlays/modals

  import {ref} from 'vue';

  import {
    Dialog,
    DialogOverlay,
    TransitionChild, 
    TransitionRoot
  } from "@headlessui/vue";

  export default {
    
    components: {
      Dialog, DialogOverlay, TransitionChild, TransitionRoot
    },

    setup() {
      const open = ref(false)
      const src = ref("")
      return { open, src }
    },

    mounted() {
      this.$emitter.on('image-modal', params => {
        this.open = true
        this.src = params.src
      })
    }

  }

</script>
