<template>


  <!-- BAD -->
  <div v-if="$wallet.state.isAdmin==false" class="container md:px-4 mx-auto mt-12 mb-6">
    <div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
      <div class="flex justify-between">
        <div class="font-bold text-gray-100 text-2xl md:text-3xl">Admin</div>
      </div>

      <div class="text-base mt-4 mb-2">
        You must be an admin to access this page.
      </div>
      <a href="#/" class="block mt-6">
        <button class="font-bold bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white px-3 py-1 rounded-full">
          <div class="flex items-center justify-center">
            <span>HOME</span> 
          </div>
        </button>
      </a>
    </div>
  </div>
  <!-- BAD -->


  <!-- CONNECT -->
  <div v-else-if="$wallet.state.currentChain==null" class="container md:px-4 mx-auto mt-12 mb-6">
    <div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
      <div class="flex justify-between">
        <div class="font-bold text-gray-100 text-2xl md:text-3xl">Admin</div>
      </div>

      <div class="text-base mt-4 mb-2">
        Connect your wallet to access the administraton panel
      </div>
      <a href="#/" class="block mt-6">
        <button class="font-bold bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white px-3 py-1 rounded-full">
          <div class="flex items-center justify-center">
            <span>HOME</span> 
          </div>
        </button>
      </a>
    </div>
  </div>
  <!-- CONNECT -->


  <div v-else>
    <!-- ADMIN -->
    <div class="container md:px-4 mx-auto mt-12 mb-6">
      <div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
        <div class="flex justify-between mb-2">
          <div class="font-bold text-gray-100 text-2xl md:text-3xl">Contracts</div>
        </div>

        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2 flex items-center">
            <span>Network</span> 
            <img :src="$wallet.state.currentChain.image" class="ml-1 h-5 min-w-max rounded-full"/>
           </div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{$wallet.state.currentChain.name}} - {{$wallet.state.chainId}}</div>
        </div>
        <div class="lg:flex mt-6">
            <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2 space-x-1 flex items-center">
              <span>SnowflakeCollection</span>
              <div v-if="$wallet.methods.isConnected()" class="inline-block cursor-pointer" @click="$wallet.methods.suggestToken()">
                <img src="https://static.coingecko.com/s/metamask_fox-11b1aab7f9a07cbe8903d8d6eb1e6d42be66d1bdd838c10786c1c49a2efb36f0.svg" class="h-5 min-w-max"/>
              </div>
            </div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <span class="font-mono overflow-hidden">{{$wallet.state.currentChain.contracts.SnowflakeCollection.address}}</span>
            <a target="_blank" :href="$wallet.state.currentChain.etherscan_address_url+$wallet.state.currentChain.contracts.SnowflakeCollection.address" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">etherscan</a>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">SnowflakeThoughts</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <span class="font-mono overflow-hidden">{{$wallet.state.currentChain.contracts.SnowflakeThoughts.address}}</span>
            <a target="_blank" :href="$wallet.state.currentChain.etherscan_address_url+$wallet.state.currentChain.contracts.SnowflakeThoughts.address" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">etherscan</a>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">SnowflakeGenerator</div>
          <a :href="$wallet.state.currentChain.etherscan_address_url+$wallet.state.currentChain.contracts.SnowflakeThoughts.address" class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{snowflakeGenerator}}</a>
        </div>
      </div>

    </div>
    <!-- ADMIN -->


    <!-- COLLECTION -->
    <div class="container md:px-4 mx-auto mt-8 mb-6">
      <div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
        <div class="flex items-end mb-2 justify-between">
          <div class="font-bold text-gray-100 text-2xl md:text-3xl">SnowflakeCollection</div>
          <div>
            <button @click="onCollectionTogglePause" :class="collectionPaused?'bg-red-600 border-red-700 hover:bg-red-500':'bg-green-600 border-green-700 hover:bg-green-500'" class="px-2 py-0 border-2 text-white rounded-full">paused={{collectionPaused}}</button>
          </div>
        </div>

        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">balance</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <span class="font-mono">{{$common.formatBalance($BN(profitBalance).div(10**18))}} ETH</span>
            <button @click="onWithdraw" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">withdraw</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">totalProfit</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{$common.formatBalance($BN(totalProfit).div(10**18))}} ETH</div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">totalSupply/Max</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <div class="font-mono">{{totalSupply}} / {{maxSupply}}</div>
            <router-link :to="{name:'AdminGallery'}"><button class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">view</button></router-link>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">cost ETH</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="Cost to mint an NFT" v-model="newCost"/>
            <button @click="onSetCost" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">paidMintCount</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{paidMintCount}}</div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">freeMintCount</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{freeMintCount}}</div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">freeMintTotal</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="Total free mints" v-model="freeMintTotal"/>
            <button @click="onSetFreeMintTotal" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">freeMintPerAddress</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="How many free mints per address" v-model="freeMintPerAddress"/>
            <button @click="onSetFreeMintPerAddress" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">webURI</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input  class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="(uri)" v-model="webURI"/>
            <button @click="onSetWebURI" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">contractURI</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input  class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="(uri)" v-model="contractURI"/>
            <button @click="onSetContractURI" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>

        <hr class="my-6 border-2 border-white border-opacity-10"/>

        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">adminMint</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input  class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="(to,start,count)" v-model="ownerMintParams"/>
            <button @click="onOwnerMint" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">mint</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">adminSetAddressFreeMintCount</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input  class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="(address,count)" v-model="addressFreeMintCountParams"/>
            <button @click="onSetAddressFreeMintCount" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">adminSetAutoWithdrawal</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input  class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="(on,address,minimum)" v-model="autoWithdrawalParams"/>
            <button @click="onSetAutoWithdrawal" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>

      </div>
    </div>
    <!-- COLLECTION -->


    <!-- THOUGHTS -->
    <div class="container md:px-4 mx-auto mt-12 mb-6">
      <div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
        <div class="flex justify-between mb-2">
          <div class="font-bold text-gray-100 text-2xl md:text-3xl">SnowflakeThoughts</div>
          <div>
            <button @click="onThoughtsTogglePause" :class="thoughtsPaused?'bg-red-600 border-red-700 hover:bg-red-500':'bg-green-600 border-green-700 hover:bg-green-500'" class="px-2 py-0 border-2 text-white rounded-full">paused={{thoughtsPaused}}</button>
          </div>
        </div>

        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">messageCount</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{messageCount}}</div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">maxMessageLength</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="Max message length" v-model="maxMessageLengthParams"/>
            <button @click="onSetMaxMessageLength" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">set</button>
          </div>
        </div>

        <hr class="my-6 border-2 border-white border-opacity-10"/>

        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">adminBump</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="(tokenId)" v-model="bumpParams"/>
            <button @click="onBump" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">ban</button>
          </div>
        </div>
        <div class="lg:flex mt-6">
          <div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">adminSetMessageBan</div>
          <div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
            <input class="block font-mono w-full rounded-lg focus:border-indigo-500 focus:ring-0 bg-transparent outline-none text-white" placeholder="(tokenId,ban)" v-model="messageBanParams"/>
            <button @click="onSetMessageBan" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">ban</button>
          </div>
        </div>

      </div>

    </div>
    <!-- THOUGHTS -->

  </div>


</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import { ethers } from "ethers"
  import IdleReloadMixin from "../mixins/IdleReloadMixin"

  const REFRESH_SECS = 10;

  export default {
    
    mixins: [IdleReloadMixin],

    setup() {
      const snowflakeGenerator = ref(null)
      const isRefreshing = ref(false)
      const collectionPaused = ref(false)
      const thoughtsPaused = ref(false)
      const cost = ref(0)
      const newCost = ref(0)
      const maxSupply = ref(0)
      const totalSupply = ref(0)
      const messageCount = ref(0)
      const freeMintTotal = ref(0)
      const freeMintCount = ref(0)
      const freeMintPerAddress = ref(0)
      const paidMintCount = ref(0)
      const profitBalance = ref(0)
      const totalProfit = ref(0)
      const maxMessageLength = ref(0)
      const ownerMintParams = ref(null)
      const webURI = ref(``)
      const contractURI = ref(``)
      const bumpParams = ref(``)
      const messageBanParams = ref(`1, true`)
      const addressFreeMintCountParams = ref(`?, 0`)
      const maxMessageLengthParams = ref(150)
      const autoWithdrawalParams = ref(``)

      return { 
        cost, 
        newCost, 
        maxSupply, 
        totalSupply, 
        messageCount, 
        snowflakeGenerator, 
        freeMintTotal, 
        freeMintCount, 
        paidMintCount, 
        profitBalance, 
        totalProfit, 
        isRefreshing, 
        maxMessageLength,
        ownerMintParams,
        messageBanParams,
        maxMessageLengthParams,
        collectionPaused,
        thoughtsPaused,
        webURI,
        contractURI,
        freeMintPerAddress,
        addressFreeMintCountParams,
        bumpParams,
        autoWithdrawalParams
      };
    },

    async created() {
      this.$emitter.on('wallet-connect', this.refreshData)
      this.$emitter.on('wallet-disconnect', this.clearData)
      this.$emitter.on('refresh-data', this.refreshData)
    },

    async mounted() {
      await this.idleStart();
    },
    
    async beforeUnmount() {
      this.$emitter.off('wallet-connect')
      this.$emitter.off('wallet-disconnect')
      this.$emitter.off('refresh-data')
    },

    methods: {
      
      async idleStart() {
        await this.refreshData();
        this.idleReloadStart(REFRESH_SECS,this.idleStart)
      },

      async clearData() {
        this.snowflakeGenerator = null
      },

      async refreshData() {
        if(this.$wallet.state.address==null || this.$wallet.state.currentChain==null || this.isRefreshing==true) return;
        this.isRefreshing = true

        let p = new ethers.providers.Web3Provider(this.$wallet.state.provider)

        // Collection data
        let collection = this.$wallet.state.currentChain.contracts.SnowflakeCollection.exec
        this.collectionPaused = await collection.paused()
        this.cost = this.$BN(await collection.cost())
        if(this.newCost==0) this.newCost = this.$common.formatBalance(this.$BN(this.cost).div(10**18))
        this.maxSupply = parseInt(await collection.maxSupply())
        this.totalSupply = parseInt(await collection.totalSupply())
        this.snowflakeGenerator = await collection.snowflakeGenerator()
        this.freeMintTotal = await collection.freeMintTotal()
        this.freeMintCount = await collection.freeMintCount()
        this.paidMintCount = await collection.paidMintCount()
        this.freeMintPerAddress =  await collection.freeMintPerAddress()
        this.profitBalance = await p.getBalance(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address)
        this.totalProfit = await collection.totalProfit()
        if(this.ownerMintParams==null) this.ownerMintParams = `${this.$wallet.state.address}, 0, 1`;
        this.addressFreeMintCountParams = `${this.$wallet.state.address}, 0`;
        this.contractURI = await collection.contractURI()
        this.webURI = await collection.webURI()
        
        // Collection auto-withdrawal
        let n = await collection.adminGetAutoWithdrawal({from:this.$wallet.state.address})
        this.autoWithdrawalParams = n[0] + ", " + n[1] + ", " + this.$BN(n[2]).div(10**18)

        // Thoughts data
        let thoughts = this.$wallet.state.currentChain.contracts.SnowflakeThoughts.exec
        this.thoughtsPaused = await thoughts.paused()
        this.messageCount = await thoughts.messageCount()
        this.maxMessageLength = this.maxMessageLengthParams = await thoughts.maxMessageLength()

        this.$forceUpdate();
        this.isRefreshing = false
      },

      async onWithdraw() {
        this.idleReloadReset()

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminWithdraw({from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Withdraw transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Withdraw transaction started.`})
      },

      async onSetCost() {
        this.idleReloadReset()

        let newCostString = this.$BN(this.newCost).mul(10**18).toString()
        console.log(`setCost(${newCostString})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminSetCost(newCostString,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set cost transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set cost transaction started.`})
      },

      async onOwnerMint() {
        this.idleReloadReset()

        let params = this.ownerMintParams.split(",")
        let toAddress = params[0].trim();
        let startTokenId = parseInt(params[1]).toString();
        let count = parseInt(params[2]).toString()
        console.log(`adminMint(${toAddress},${startTokenId},${count})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminMint(toAddress,startTokenId,count,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Owner mint transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Owner mint transaction started.`})
      },

      async onSetMessageBan() {
        this.idleReloadReset()

        let params = this.messageBanParams.split(",")
        let tokenId = params[0].trim();
        let ban = params[1].trim()=='true'
        console.log(`adminSetMessageBan(${tokenId},${ban})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeThoughts.address, this.$wallet.state.abis.SnowflakeThoughts, provider.getSigner())  
        let tx = await snowSigned.adminSetMessageBan(tokenId,ban,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$wallet.methods.clearThought(tokenId)
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set message ban transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set message ban transaction started.`})
      },

      async onSetMaxMessageLength() {
        this.idleReloadReset()

        console.log(`setMessageBan(${this.maxMessageLengthParams})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeThoughts.address, this.$wallet.state.abis.SnowflakeThoughts, provider.getSigner())  
        let tx = await snowSigned.adminSetMaxMessageLength(this.maxMessageLengthParams,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set max message length transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set max message length transaction started.`})
      },

      async onSetFreeMintTotal() {
        this.idleReloadReset()

        console.log(`adminSetFreeMintTotal(${this.freeMintTotal})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminSetFreeMintTotal(this.freeMintTotal,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set free mint total transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set free mint total transaction started.`})
      },

      async onSetFreeMintPerAddress() {
        this.idleReloadReset()

        console.log(`adminSetFreeMintPerAddress(${this.freeMintPerAddress})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminSetFreeMintPerAddress(this.freeMintPerAddress,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set free mint per address transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set free mint per address transaction started.`})
      },

      async onSetAddressFreeMintCount() {
        this.idleReloadReset()

        let params = this.addressFreeMintCountParams.split(",")
        let address = params[0].trim();
        let count = parseInt(params[1].trim())
        console.log(`adminSetAddressFreeMintCount(${address},${count})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.setAddressFreeMintCount(address,count,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set address free mint count transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set address free mint count transaction started.`})
      },

      async onSetAutoWithdrawal() {
        this.idleReloadReset()

        let params = this.autoWithdrawalParams.split(",")
        let on = params[0]=='true'
        let address = params[1].trim()
        let minimum = this.$BN(params[2].trim()).mul(10**18).toString()
        console.log(`adminSetAutoWithdrawal(${on},${address},${minimum})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminSetAutoWithdrawal(on,address,minimum,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set autowithdrawal transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set autowithdrawal transaction started.`})
      },

      async onCollectionTogglePause() {
        this.idleReloadReset()
        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        
        if(this.collectionPaused==false) { //Its running now, so pause it
          console.log(`pause()`)
          let tx = await snowSigned.pause({from:this.$wallet.state.address})
          tx.wait().then(()=>{
            this.collectionPaused = true
            this.$emitter.emit('notification', {variant:'success',message:`Pause transaction complete.`})
          })

          this.$emitter.emit('notification', {variant:'success',message:`Pause transaction started.`})
        }
        else { //It's paused now (true), so unpause it
          console.log(`unpause()`)
          let tx = await snowSigned.unpause({from:this.$wallet.state.address})
          tx.wait().then(()=>{
            this.collectionPaused = false
            this.$emitter.emit('notification', {variant:'success',message:`Unpause transaction complete.`})
          })

          this.$emitter.emit('notification', {variant:'success',message:`Unpause transaction started.`})
        }
      },

      async onThoughtsTogglePause() {
        this.idleReloadReset()
        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeThoughts.address, this.$wallet.state.abis.SnowflakeThoughts, provider.getSigner())  
        
        if(this.thoughtsPaused==false) { //Its running now, so pause it
          console.log(`pause()`)
          let tx = await snowSigned.pause({from:this.$wallet.state.address})
          tx.wait().then(()=>{
            this.thoughtsPaused = true
            this.$emitter.emit('notification', {variant:'success',message:`Pause transaction complete.`})
          })

          this.$emitter.emit('notification', {variant:'success',message:`Pause transaction started.`})
        }
        else { //It's paused now (true), so unpause it
          console.log(`unpause()`)
          let tx = await snowSigned.unpause({from:this.$wallet.state.address})
          tx.wait().then(()=>{
            this.thoughtsPaused = false
            this.$emitter.emit('notification', {variant:'success',message:`Unpause transaction complete.`})
          })

          this.$emitter.emit('notification', {variant:'success',message:`Unpause transaction started.`})
        }
      },

      async onSetWebURI() {
        this.idleReloadReset()

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminSetWebURI(this.webURI,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set contract URI transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set contract URI transaction started.`})
      },

      async onSetContractURI() {
        this.idleReloadReset()

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
        let tx = await snowSigned.adminSetContractURI(this.contractURI,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Set contract URI transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Set contract URI transaction started.`})
      },

      async onBump() {
        this.idleReloadReset()

        console.log(`setMessageBan(${this.maxMessageLengthParams})`)

        let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
        let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeThoughts.address, this.$wallet.state.abis.SnowflakeThoughts, provider.getSigner())  
        let tx = await snowSigned.adminBump(this.bumpParams,{from:this.$wallet.state.address})
        tx.wait().then(()=>{
          this.$emitter.emit('refresh-data') 
          this.$emitter.emit('notification', {variant:'success',message:`Bump message transaction complete.`})
        })

        this.$emitter.emit('notification', {variant:'success',message:`Bump message transaction started.`})
      },

    }
  }
</script>
