<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open=false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>

              <!-- ICON -->
              <div v-if="variant=='success'" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-200 text-green-500">
                <i class="fa fa-check"/>
              </div>
              <div v-else-if="variant=='info'" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-300 text-blue-700">
                <i class="fa fa-check"/>
              </div>
              <div v-else-if="variant=='danger'" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-400 text-gray-100">
                <i class="fa fa-exclamation"/>
              </div>
              <div v-else-if="variant=='warning'" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-500 text-gray-100">
                <i class="fa fa-exclamation"/>
              </div>
              <!-- ICON -->

              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle class="text-xl leading-6 font-medium text-gray-900">
                  {{title}}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500" v-html="message"/>
                </div>
              </div>
            </div>
            <div class="mt-4 sm:mt-6">
              <button class="font-bold w-full mt-8 bg-indigo-500 hover:bg-indigo-400 border-2 border-indigo-600 text-white px-3 py-1 rounded-full focus:outline-none focus:ring focus:border-indigo-300" @click="open=false">{{buttonTitle}}</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
  // https://tailwindui.com/components/application-ui/overlays/modals

  import {ref} from 'vue';

  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionChild, 
    TransitionRoot
  } from "@headlessui/vue";

  export default {
    
    components: {
      Dialog, DialogOverlay, DialogTitle, DialogDescription, TransitionChild, TransitionRoot
    },

    setup() {
      const open = ref(false)
      const title = ref(false)
      const message = ref(false)
      const buttonTitle = ref(false)
      const variant = ref('success')
      return { open, title, message, buttonTitle, variant }
    },

    mounted() {
      this.$emitter.on('ok-modal', params => {
        this.open = true
        this.title = params.title || "Title"
        this.message = params.message || "Message"
        this.buttonTitle = params.buttonTitle || "OK"
        this.variant = params.variant || 'success'
      })
    }

  }

</script>
