import {reactive} from 'vue'
import {abi as SnowflakeCollectionAbi} from '../../ethereum/build/contracts/SnowflakeCollection.json'
import {abi as SnowflakeThoughtsAbi} from '../../ethereum/build/contracts/SnowflakeThoughts.json'
import {abi as ERC20Abi} from '../contracts/ERC20.json'
import EthereumMainChain from './chains/eth-main';
import EthereumRinkebyChain from './chains/eth-rinkeby';
//import PolygonMumbaiChain from './chains/polygon-mumbai';
//import PolygonMainChain from './chains/polygon-main';
//import GanacheChain from './chains/ganache-laptop';
//import GanacheChain from './chains/ganache-macmini';
import 'keccak256'
import app from './main'
import { ethers } from "ethers"
import moment from 'moment-timezone'
	
import Web3 from "web3"
import Web3Modal from "web3modal"
import WalletConnectProvider from "@walletconnect/web3-provider"
import Torus from "@toruslabs/torus-embed";
import { WalletLink } from 'walletlink';

const INFURA_ID = '297809395e854a2892b61bf6721c4795';
const REGEX_LINKS = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
const REGEX_IMAGES = /(https?:\/\/[^ ]*\.(?:gif|png|jpg|jpeg))/i


// For web3 modal, supports Metamask, Trust Wallet (wallet connect) and Coinbase (wallet link)
const providerOptions = {
				
	walletconnect: {
		package: WalletConnectProvider,
		options: {
			infuraId: INFURA_ID, //ts1176
		}
	},

	'custom-walletlink': { //Coinbase
		display: {
			logo: 'images/walletlink-coinbase.png', 
			name: 'Coinbase Wallet',
			description: 'Scan with WalletLink to connect',
		},
		options: {
			appName: 'Snowflake Collection', // Your app name
			networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
			chainId: 1,
		},
		package: WalletLink,
		connector: async (_, options) => {
			const { appName, networkUrl, chainId } = options
			const walletLink = new WalletLink({
				appName
			});
			const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
			await provider.enable()
			return provider
		},
	},

  torus: {
    package: Torus, // required
  }

}


const state = reactive({
	web3Modal: null,
	provider: null,
	address: null,
	chainId: null,
	currentChain: null,

	// One-time
	isAdmin: false,
	maxSupply: 10000,

	// Snowflakes
	snowflakes: {},
	adminSnowflakes: {},
	thoughts: {},
	thoughtByIndex: {},

	roles: {
		MINTER_ROLE: keccak256('MINTER_ROLE'),
		PAUSER_ROLE: keccak256('PAUSER_ROLE')
	},

	abis: {
		ERC20: ERC20Abi,
		SnowflakeCollection: SnowflakeCollectionAbi,
		SnowflakeThoughts: SnowflakeThoughtsAbi
	},
	
	chains: {
		// Chains from JSON files will be inserted on init
	}

})


const methods = {

	isConnected() {
		return state.address!=null && state.currentChain!=null
	},

  findImage(txt) {
    let result =  REGEX_IMAGES.exec(txt)
    if(result!=null) return result[0]
    return null
  },

  // Convert anything with protocol to a link
  textWithLinks(text) {
    return text.replace(REGEX_LINKS, url => {
      let shortUrl = url.replace(/(^\w+:|^)\/\//, '');
      return `<a href="${url}" target=_blank" class="underline text-blue-400">${shortUrl}</a>`;
    })
  },

	async init() {
	
		//Add chains
		state.chains[EthereumMainChain.chainId] = EthereumMainChain
		state.chains[EthereumRinkebyChain.chainId] = EthereumRinkebyChain
		//state.chains[PolygonMainChain.chainId] = PolygonMainChain
		//state.chains[PolygonMumbaiChain.chainId] = PolygonMumbaiChain
		//state.chains[GanacheChain.chainId] = GanacheChain

		// Create web3
		state.web3Modal = new Web3Modal({
			network: "mainnet", 
			cacheProvider: false,
			disableInjectedProvider: false,
			providerOptions
		});

	},

	async connect() {
		console.log("connect")

		// Open wallet selection dialog
		try {
			state.provider = await state.web3Modal.connect()
			state.provider.on("accountsChanged", accounts => this.fetchAccountData())  // Subscribe to accounts change
			state.provider.on("chainChanged", chainId => this.fetchAccountData())  // Subscribe to chainId change
			state.provider.on("networkChanged", networkId => this.fetchAccountData())  // Subscribe to networkId change
			await this.fetchAccountData()
		} 
		catch(e) {
			console.log("Could not get a wallet connection", e)
			app.config.globalProperties.$emitter.emit('notification',{variant:'danger',message:"Could not connect wallet."})
			return
		}
	},

	async disconnect() {
		console.log("disconnect")
		state.address = null
		state.currentChain = null
		state.isAdmin = false

		if(state.web3Modal!=null && state.web3Modal.clearCachedProvider!=null) {
			console.log("web3Modal.clearCachedProvider")
			await state.web3Modal.clearCachedProvider()
		}

		if(state.provider!=null && state.provider.close!=null) {
			console.log("provider.close")
			await state.provider.close()
		}

		app.config.globalProperties.$emitter.emit('wallet-disconnect')
	},


	async fetchAccountData() {
		if(state.provider == null) return
		
		const web3 = new Web3(state.provider)
		
		// Get chain
		let chainId = await web3.eth.getChainId()
		if(typeof(chainId)=='number') chainId='0x'+chainId //Maybe a number, put 0x in front
		if(state.chains[chainId]==null) {
			state.address = null;
			state.chainId = null
			state.currentChain = null
			this.disconnect()

			console.log("chainChanged failed = "+chainId)
			app.config.globalProperties.$emitter.emit('notification',{variant:'danger',message:"Please switch to Ethereum Mainnet."})
			return
		}
		else {
			state.chainId = chainId;
			state.currentChain = state.chains[chainId]

			// Get contracts with addresses
			let p = new ethers.providers.Web3Provider(state.provider)
			for(let k in state.chains) {
				let network = state.chains[k];
				network.contracts.SnowflakeCollection.exec = new ethers.Contract(network.contracts.SnowflakeCollection.address, state.abis.SnowflakeCollection, p)
				network.contracts.SnowflakeThoughts.exec = new ethers.Contract(network.contracts.SnowflakeThoughts.address, state.abis.SnowflakeThoughts, p)
			}

			state.thoughts = {}
			state.thoughtByIndex = {}
			state.snowflakes = {}
			state.adminSnowflakes = {}

			//console.log("chainId="+chainId)
		}

		// Get account
		const accounts = await web3.eth.getAccounts()
		//console.log("accounts=", accounts)
		if (accounts.length === 0) {
			state.address = null;
			app.config.globalProperties.$emitter.emit('notification',{variant:'danger',message:"Please connect to your wallet."})
			return
		}
		else if(accounts[0]!=state.address) {
			const account0 = accounts[0]
			//console.log('address='+account0+" chain="+state.currentChain.chainId)
			state.address = account0

			// Call some basics
			let exec = state.currentChain.contracts.SnowflakeCollection.exec
			state.isAdmin = await exec.hasRole(state.roles.MINTER_ROLE,state.address)
			state.balance = parseInt((await exec.balanceOf(state.address)).toString())
			state.maxSupply = await exec.maxSupply()

			app.config.globalProperties.$emitter.emit('wallet-connect')
		}
	},
	
	xlatWeb3Error(err) {
		let d = err.message;
		if(d!=null && d.startsWith('Internal JSON-RPC error.')) {
			try {
				d = JSON.parse(d.substr(25));
				d = d.message;
				if(d!=null) {
					if(d.startsWith("VM Exception while processing transaction: revert")) d = d.substr(50)
					else if(d=="ERC20: transfer amount exceeds balance.") d = 'Not enough tokens'
				}
			}
			catch(err) {
				d = err.message;
			}
		}
		else if(d=='Already processing eth_requestAccounts. Please wait.') d='Please check your wallet for a pending transaction';
		else if(d=='MetaMask Tx Signature: User denied transaction signature.') d='You rejected the transaction';
		
		if(!d.endsWith('.')) d+='.';
		return d;
	},

	async getSnowflake(tokenId) {
		if(state.address==null) return
		if(state.snowflakes[tokenId]!=null) return state.snowflakes[tokenId]

		let collection = state.currentChain.contracts.SnowflakeCollection.exec
		let tokenRaw = await collection.tokenURI(tokenId)
		let tokenJson = JSON.parse(atob(tokenRaw.split('base64,')[1]))
		state.snowflakes[tokenId] = tokenJson
		return tokenJson
	},
	
	async getSnowflakeImage(tokenId) {
		if(state.address==null) return
		return (await this.getSnowflake(tokenId)).image
	},

	async adminGetSnowflake(tokenId) {
		if(state.address==null) return
		if(state.snowflakes[tokenId]!=null) return state.snowflakes[tokenId]
		if(state.adminSnowflakes[tokenId]!=null) return state.adminSnowflakes[tokenId]

		let collection = state.currentChain.contracts.SnowflakeCollection.exec
		let tokenRaw = await collection.adminGetTokenURI(tokenId,{from:state.address})
		let tokenJson = JSON.parse(atob(tokenRaw.split('base64,')[1]))
		state.adminSnowflakes[tokenId] = tokenJson
		return tokenJson
	},
	
	async adminGetSnowflakeImage(tokenId) {
		if(state.address==null) return
		return (await this.adminGetSnowflake(tokenId)).image
	},

  formatThoughtJson(thoughtData) {
    let thoughtMessage = thoughtData[0]

    // Find a single image and replace with blank
    let image = this.findImage(thoughtMessage)
    if(image!=null) thoughtMessage = thoughtMessage.replaceAll(image,'')

    // Find all links and href them
    let message = this.textWithLinks(thoughtMessage)

    let thoughtJson = {
      message,
      tokenId: thoughtData[1],
      sender: thoughtData[2],
      date: moment.unix(parseInt(thoughtData[3].toString())),
      id: thoughtData[4],
      rarity: thoughtData[5],
      ban: thoughtData[6]
    }

    // Replace normal snowflake with image
    if(image!=null) thoughtJson.image = image

    return thoughtJson
  },

	async getThought(tokenId) {
		if(state.address==null) return

		let n = state.thoughts[tokenId]
		if(n!=null && n.date.diff(moment(new Date()),'minutes')<3) return state.thoughts[tokenId]

		let thoughts = state.currentChain.contracts.SnowflakeThoughts.exec
		let thoughtData = await thoughts.getMessage(tokenId)
		if(thoughtData[1]>0) return this.formatThoughtJson(thoughtData)
		return null
	},

	async getThoughtByIndex(i) {
		if(state.address==null) return

		let n = state.thoughtByIndex[i]
		if(n!=null && n.date.diff(moment(new Date()),'minutes')<3) return state.thoughtByIndex[i]

		let thoughts = state.currentChain.contracts.SnowflakeThoughts.exec
		let thoughtData = await thoughts.getMessageByIndex(i)
    let thoughtJson = this.formatThoughtJson(thoughtData)
		state.thoughtByIndex[i] = thoughtJson
		return thoughtJson
	},

	clearThought(tokenId) {
		if(state.address==null) return

		tokenId = parseInt(tokenId)
		state.thoughts[tokenId] = null
		for(let n in state.thoughtByIndex) {
			if(state.thoughtByIndex[n]!=null && state.thoughtByIndex[n].tokenId==tokenId) {
				delete state.thoughtByIndex[n]
				break
			}
		}
	},

	// Add token to metamask
	async suggestToken(tokenAddress,tokenSymbol,tokenDecimals,tokenImage) {
		try {
		  // wasAdded is a boolean. Like any RPC method, an error may be thrown.
		  const wasAdded = await ethereum.request({
				method: 'wallet_watchAsset',
				params: {
					type: 'ERC20', // Initially only supports ERC20, but eventually more!
					options: {
					address: state.currentChain.contracts.SnowflakeCollection.address, // The address that the token is at.
					symbol: 'SNOW', // A ticker symbol or shorthand, up to 5 chars.
					decimals: 0, // The number of decimals in the token
					image: 'https://www.snowflakecollection.com/favicon/apple-touch-icon.png', // A string url of the token logo
					},
				},
		  });
  
		  if (wasAdded) {
				console.log('Token added');
		  } 
			else {
				console.log('Token rejected');
		  }
		} 
		catch (error) {
		  console.log(error);
		}
	},

}


export default {
	state,
	methods
}