import { createRouter, createWebHashHistory } from 'vue-router'
import Home from './views/Home.vue'
import Thoughts from './views/Thoughts.vue'
import Account from './views/Account.vue'
import About from './views/About.vue'
import Gallery from './views/Gallery.vue'
import Admin from './views/Admin.vue'
import AdminGallery from './views/AdminGallery.vue'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {path:'/',name:'Home',component:Home},
    {path:'/thoughts',name:'Thoughts',component:Thoughts},
    {path:'/account',name:'Account',component:Account},
    {path:'/about',name:'About',component:About},
    {path:'/gallery',name:'Gallery',component:Gallery},
    {path:'/admin',name:'Admin',component:Admin},
    {path:'/admin/gallery',name:'AdminGallery',component:AdminGallery},
  ]
})

// Scroll back to top on each route change
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router