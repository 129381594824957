<template>
	<div>
		<OkModal/>
		<ConfirmModal/>
		<ImageModal/>
		<Notification/>
		<ThoughtInputModal/>

		<div class="h-full">

			<!-- LOGO -->  
			<router-link :to="{name:'Home'}" class="w-full p-4 md:mb-2">
				<img src="images/logo_only.png" class="mx-auto w-2/4 md:w-2/5 max-w-md"/>
			</router-link>
			<!-- LOGO -->  

			<!--Nav-->
			<div class="container w-full md:w-4/5 mx-auto">
				<div class="flex items-center justify-between sm:mx-10 py-1 px-3 sm:rounded-full shadow-lg md:border-4 border-t-4 border-b-4 card-bg">
					
					<div class="flex items-center mx-1">          
						<router-link :to="{name:'Home'}" :class="$route.name=='Home'?'bg-opacity-20 hover:bg-opacity-30':' bg-opacity-5 hover:bg-opacity-10'" class="text-white no-underline hover:no-underline mr-2 md:mr-3 px-3 py-1 rounded-full bg-white text-sm">
							<i class="fa fa-home"/>
						</router-link>
						<router-link :to="{name:'Gallery'}" :class="$route.name=='Gallery'?'bg-opacity-20 hover:bg-opacity-30':' bg-opacity-5 hover:bg-opacity-10'" class="text-white no-underline hover:no-underline mr-2 md:mr-3 px-3 py-1 rounded-full bg-white text-sm">
							GALLERY
						</router-link>
						<router-link :to="{name:'Thoughts'}" :class="$route.name=='Thoughts'?'bg-opacity-20 hover:bg-opacity-30':' bg-opacity-5 hover:bg-opacity-10'" class="text-white no-underline hover:no-underline px-3 py-1 rounded-full bg-white text-sm">
							CLUB
						</router-link>
					</div>

					<div class="flex w-1/2 justify-end content-center">
						<button v-if="$wallet.methods.isConnected()==false" class="ml-2 font-bold border-2 border-yellow-800 bg-yellow-700 hover:bg-opacity-90 bg-opacity-70 text-white px-2 py-1 rounded-full text-sm" @click="connectWallet">
							CONNECT<i class="ml-2 fas fa-wallet"></i>
						</button>
						<div v-else>
							<button :class="$route.name=='Account'?'bg-opacity-70 hover:bg-opacity-80':'bg-opacity-40 hover:bg-opacity-50'" class="ml-2 md:ml-3 font-bold bg-green-600 text-white px-2 py-1 rounded-l-lg text-sm h-7" @click="onAccount">
								<span v-html="$common.shortenAddressHtml($wallet.state.address)"/>
							</button>
							<button :class="$route.name=='Account'?'bg-opacity-70 hover:bg-opacity-80':'bg-opacity-40 hover:bg-opacity-50'" class="font-bold bg-red-600 text-white px-2 py-1 rounded-r-lg text-sm h-7" @click="onDisconnect">
								<i class="fa fa-power-off"/>
							</button>
						</div>
					</div>
				</div>
			</div>
			<!--Nav-->

			<router-view/>

			<!--Footer-->
			<div class="pt-12 pb-20 h-full my-12">
				<div class="w-full text-md text-center fade-in">
					<div class="mb-4">
						<router-link tag="a" :to="{name:'Home'}" class="underline m-2">Home</router-link>
						<router-link tag="a" :to="{name:'Gallery'}" class="underline m-2">Gallery</router-link>
						<router-link tag="a" :to="{name:'Thoughts'}" class="underline m-2">Club</router-link>
						<router-link v-if="$wallet.methods.isConnected()==true" tag="a" :to="{name:'Account'}" class="underline m-2">Account</router-link>
						<router-link tag="a" :to="{name:'About'}" class="underline m-2">About</router-link>
						<a class="underline m-2" target="_blank" :href="this.$store.state.OPENSEA_URL">OpenSea</a>
					</div>
					<div>
						<span class="text-gray-300 no-underline hover:no-underline m-2">&copy; Snowflake Collection 2022</span>
						<router-link v-if="$wallet.state.isAdmin==true" tag="a" :to="{name:'Admin'}" class="underline m-2 text-sm"><i class="fa fa-wrench"/></router-link>
						<a class="underline m-2" target="_blank" :href="this.$store.state.FB_URL"><i class="fab fa-facebook"></i></a>
						<a class="underline m-2" target="_blank" :href="this.$store.state.TWITTER_URL"><i class="fab fa-twitter"></i></a>
						<a class="underline m-2" target="_blank" :href="this.$store.state.DISCORD_URL"><i class="fab fa-discord"></i></a>
					</div>
				</div>
			</div>
			<!--Footer-->
					
		</div>
	</div>
</template>

<style lang="scss" scoped>
</style>

<script>
  import './assets/tailwind.css'
  import OkModal from './components/OkModal.vue'
  import ConfirmModal from './components/ConfirmModal.vue'
  import ImageModal from './components/ImageModal.vue'
  import ThoughtInputModal from './components/ThoughtInputModal.vue'
	import Notification from './components/Notification.vue'
  
  export default {
    components: {OkModal, ConfirmModal, ImageModal, ThoughtInputModal, Notification},

    async created() {
    },

    async mounted() {
      await this.$wallet.methods.init();
		},

    methods: {
     
      async connectWallet() {
        try {
          await this.$wallet.methods.connect()
        }
        catch(err) {
          console.log(err)
          this.$emitter.emit('ok-modal', {
            title: "Wallet Error",
            variant: "danger",
            message: this.$wallet.methods.xlatWeb3Error(err)
          });
        }
      },

			async onDisconnect() {
				try {
          await this.$wallet.methods.disconnect()
        }
        catch(err) {
          console.log(err)
          this.$emitter.emit('ok-modal', {
            title: "Wallet Error",
            variant: "danger",
            message: this.$wallet.methods.xlatWeb3Error(err)
          });
        }
			},

      onAccount() {
        this.$router.push({name:'Account'})
      }
      
    }

  }
</script>
