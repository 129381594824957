export default {
  chainId: '0x4',
  name: "Ethereum Rinkeby",
  etherscan_address_url: "https://rinkeby.etherscan.io/address/",
  etherscan_tx_url: "https://rinkeby.etherscan.io/tx/",
  image: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png", //Native coin image (use in login)
  contracts: {
    SnowflakeCollection: {
      exec: null,
      address: '0x92e8749bD9D7d2C4Be7B2282E8eE2a45A18AC8b7',
    },
    SnowflakeThoughts: {
      exec: null,
      address: '0x41eab9d14E93434e74A9AE0eB9B9081521B12ae3',
    }
  }  
}