<template>
	<div>
		<ThoughtInputModal/>

		<div class="container md:px-4 mx-auto mt-12 mb-6">
			<div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
				
				<div class="flex justify-between">
					<div class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Snowflake Gallery</div>
					<div v-if="$wallet.methods.isConnected()==true">
						<button @click="onRefresh" class="font-bold text-gray-400 text-sm bg-black rounded-full px-3 py-1 bg-opacity-10 hover:bg-opacity-20">
							<div class="flex items-center justify-center">
								<span>REFRESH</span> 
								<i class="fa fa-sync-alt ml-1"/>
							</div>
						</button>
					</div>
				</div>

				<div class="text-base mb-2">
					Our beautiful of collection of animated snowflakes was created by our designers and generated on the blockchain by our humble developers.  
					Each one is unique just like you and gives its owners the exclusive right to our <router-link :to="{name:'Thoughts'}" class="underline">Thoughts</router-link> decentralized public message board where you can express yourself to the world.
					If you would like to own a Snowflake you can trade on <a :href="$store.state.OPENSEA_URL" target="_blank" class="underline">OpenSea</a> or <router-link :to="{name:'Home'}" class="underline">mint</router-link> one, but hurry supplies are limited!
				</div>

				<div v-if="$wallet.state.address==null" class="px-3 py-2 bg-black bg-opacity-10 rounded-full mt-12 text-center">
					Connect Your Wallet
				</div>
				<div v-else-if="snowflakes.length==0" class="px-3 py-2 bg-black bg-opacity-10 rounded-full mt-12 text-center">
					No Snowflakes Yet
				</div>

			</div>
		</div>


		<!-- PAGINATOR -->
		<paginator v-if="totalPages>1" class="mt-12 mb-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
		<!-- PAGINATOR -->


		<!-- GALLERY -->
		<div class="container md:px-4 mx-auto">
			<div v-if="snowflakes.length>0" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-5 mx-2 md:mx-0">
				<div v-for="n of snowflakes" v-bind:key="n.tokenId">
					<div class="w-full lg:h-full p-3 md:p-5 shadow-xl rounded-lg border-4 card-bg">
						
						<div class="w-full mb-3">
							<img v-if="n.data==null" src="/images/snowflake-empty.svg" class="rounded-lg"/>
							<img v-else @click="imageClicked(n.data.image)" :src="n.data.image" class="cursor-pointer rounded-lg"/>
						</div>

						<div class="mt-2 flex justify-between bg-black bg-opacity-20 rounded-lg w-full px-2 py-1 md:px-3 md:py-2 text-sm">
							<div v-if="n.data!=null">
								<span class="hidden xl:inline-block font-bold">Snowflake&nbsp;</span>
								<span class="text-gray-400 text-sm">#</span>
								<span class="font-bold">{{n.data.tokenId}}</span>
							</div>

							<div v-if="n.data!=null && n.data.owner!=null" class="text-right">
								<span v-if="$common.addressEquals(n.data.owner,$wallet.state.address)" class="text-yellow-400" style="padding-top:2px;padding-bottom:2px">YOU</span>
								<span v-else style="padding-top:2px;padding-bottom:2px" v-html="$common.shortenAddressHtml(n.data.owner)"/>
							</div>
							<div v-else>
								&nbsp;
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- GALLERY -->


		<!-- PAGINATOR -->
		<paginator v-if="totalPages>1" class="my-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
		<!-- PAGINATOR -->
		
	</div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import { ethers } from "ethers"
  import IdleReloadMixin from "../mixins/IdleReloadMixin"
  import Paginator from "../components/Paginator"
  import ThoughtInputModal from '../components/ThoughtInputModal.vue'

  const PER_PAGE = 12

  export default {

    mixins: [IdleReloadMixin],
    
    components: {Paginator,ThoughtInputModal},

    setup() {
      const currentPage = ref(0)
      const totalPages = ref(0)
      const snowflakes = ref([])
      const totalSupply = ref(0)
      const isRefreshing = ref(false)

      return { snowflakes, totalSupply, currentPage, totalPages, isRefreshing };
    },

    async created() {
      this.$emitter.on('wallet-connect', this.refreshData)
      this.$emitter.on('wallet-disconnect', this.clearData)
      this.$emitter.on('refresh-data', this.refreshData)
    },

    async mounted() {
      await this.refreshData();
    },
    
    async beforeUnmount() {
      this.$emitter.off('wallet-connect')
      this.$emitter.off('wallet-disconnect')
      this.$emitter.off('refresh-data')
    },

    methods: {
      
      async clearData() {
        this.currentPage = 0
        this.totalPages = 0
        this.messageCount = 0
        this.snowflakes.length = 0
        this.isRefreshing = false
      },

      async refreshData() {
        if(this.$wallet.state.currentChain==null || this.isRefreshing==true) return;
        this.isRefreshing = true
        
        let c = this.$wallet.state.currentChain.contracts.SnowflakeCollection.exec
        this.totalSupply = parseInt(await c.totalSupply())
        
        //Get thoughts, sort while we load
        let startIndex = this.currentPage * PER_PAGE
        let endIndex = Math.min(this.totalSupply,startIndex+PER_PAGE)
        this.totalPages = Math.ceil(this.totalSupply/PER_PAGE)
        if(this.currentPage>=this.totalPages) this.currentPage = this.totalPages - 1
        let arr = []
        for(let i=startIndex;i<endIndex;i++) arr.push({index:i,data:null})
        this.snowflakes = arr

        // Get snowflake and owner later
        for(let snowflake of this.snowflakes) {
          c.tokenByIndex(snowflake.index).then(tokenId=>{
            this.$wallet.methods.getSnowflake(tokenId).then(data=>{
              snowflake.data = data
              if(data.owner==null && data.external_url!=null) {
                c.ownerOf(tokenId).then(owner=>snowflake.data.owner=owner)
              }
            })
          })
        }

        this.isRefreshing = false
      },
      
      onRefresh() {
        this.$emitter.emit('notification',{variant:'success',message:"Refresh started."})
        this.clearData()
        this.refreshData()
      },

      updatePage(p) {
        this.currentPage = p;
        this.refreshData()
      },

      onEdit(n) {
        if(!this.$common.addressEquals(n.owner,this.$wallet.state.address)) return
        this.$emitter.emit('thought-input-modal',{tokenId:n.tokenId,thought:n})
      },

      imageClicked(n) {
        this.$emitter.emit('image-modal',{src:n})
      }

    },

    computed: {
    }

  }
</script>
