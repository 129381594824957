<template>
	<div>

		<ThoughtInputModal/>

		<div class="container md:px-4 mx-auto mt-12 mb-6">
			<div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
				
				<div class="flex justify-between">
					<div class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Your Snowflakes</div>
					<div v-if="$wallet.methods.isConnected()==true">
						<button @click="onLogout" class="font-bold text-gray-400 text-sm mr-2 bg-black rounded-full px-3 py-1 bg-opacity-10 hover:bg-opacity-20">
							<div class="flex items-center justify-center">
								<span>LOGOUT</span> 
								<i class="fa fa-sign-out-alt ml-1"/>
							</div>
						</button>
						<button @click="onRefresh" class="font-bold text-gray-400 text-sm bg-black rounded-full px-3 py-1 bg-opacity-10 hover:bg-opacity-20">
							<div class="flex items-center justify-center">
								<span>REFRESH</span> 
								<i class="fa fa-sync-alt ml-1"/>
							</div>
						</button>
					</div>
				</div>

				<div class="text-base">
					<span v-if="snowflakes.length>0">You have {{accountBalance}} beautiful Snowflake{{accountBalance>1?'s':''}}.</span>
					<span v-else>You currently do not have any snowflakes.</span>
					Each snowflake is unique and one-of-a-kind just like you!  
					You can click on them to view a larger picture.
					You can also click your Snowflake to edit a message that will be posted on the <router-link tag="a" :to="{name:'Thoughts'}" class="underline">Thoughts</router-link> wall for everyone in the Snowflake club to see.
				</div>

				<div class="mx-auto mt-6">
					<router-link v-if="totalSupply<maxSupply" :to="{name:'Home'}">
						<button @click="refreshData" class="font-bold border-2 text-white px-3 py-1 rounded-full bg-indigo-500 hover:bg-indigo-400 border-indigo-600">
							<div class="flex items-center justify-center">
								<span>MINT SNOWFLAKE</span>
							</div>
						</button>
					</router-link>
					<a :href="this.$store.state.OPENSEA_URL" target="_blank"><button class="ml-2 font-bold bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white px-3 py-1 rounded-full">TRADE ON OPENSEA</button></a>
				</div>

				<div v-if="$wallet.state.address==null" class="px-3 py-2 bg-black bg-opacity-10 rounded-full mt-12 text-center">
					Connect Your Wallet
				</div>

			</div>
		</div>

		<!--PAGINATOR-->
		<paginator v-if="totalPages>1" class="mt-12 mb-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
		<!--PAGINATOR-->

		<!-- SNOWFLAKES -->
		<div class="container md:px-4 mx-auto">
			<div v-if="snowflakes.length>0" class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-5">
				<div v-for="n of snowflakes" v-bind:key="n.index" class="w-full">
					<div class="w-full lg:h-full flex p-3 md:p-5 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
						
						<div class="w-2/6 mr-3">
							<img v-if="n.data==null" src="/images/snowflake-empty.svg" class="rounded-lg"/>
							<img v-else @click="imageClicked(n.data.image)" :src="n.data.image" class="cursor-pointer rounded-lg"/>
						</div>

						<button @click="onEdit(n)" class="bg-black bg-opacity-20 rounded-lg w-full px-3 py-2">
							<div class="flex justify-between">

								<div v-if="n.data!=null" class="text-left">
									<span class="text-md font-bold">Snowflake #{{n.data.tokenId}}</span>
									<div v-if="n.thought!=null" class="text-sm mt-1 mb-3">
										<span class="text-gray-300">on</span> <span class="bg-black bg-opacity-30 ml-1 px-2 py-0 rounded-lg" style="padding-top:2px;padding-bottom:2px" v-html="$common.formatDateHtml(n.thought.date)"/>
									</div>
								</div>

								<div class="font-bold text-gray-400 text-sm">
									<div class="flex items-center justify-center mt-1 text-sm">
										<span>{{n.index + 1}} / {{accountBalance}}</span> 
										<div style="padding-bottom:3px" class="ml-2"><i class="fa fa-edit text-yellow-500"/></div>
									</div>
								</div>

							</div>

							<div v-if="n.data==null || n.data.thought==null" class="text-left justify-start h-full text-gray-400 text-opacity-50">
								Your message to the world ...
							</div>
							<div v-else-if="n.data.thought.ban" class="text-left justify-start h-full text-red-500 pb-1">
								Removed
							</div>
							<div v-else class="text-left justify-start h-full text-gray-300 pb-1">
								{{n.data.thought.message}}
							</div>

						</button>

					</div>
				</div>
			</div>


			<!--PAGINATOR-->
			<paginator v-if="totalPages>1" class="my-8" :currentPage="currentPage" :totalPages="totalPages" @update="updatePage"/>
			<!--PAGINATOR-->


		</div>
		<!-- SNOWFLAKES -->

	</div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import { ethers } from "ethers"
  import IdleReloadMixin from "../mixins/IdleReloadMixin"
  import Paginator from "../components/Paginator"
  import ThoughtInputModal from '../components/ThoughtInputModal.vue'

  const PER_PAGE = 12

  export default {

    mixins: [IdleReloadMixin],
    
    components: {Paginator,ThoughtInputModal},

    setup() {
      const currentPage = ref(0)
      const totalPages = ref(0)
      const accountBalance = ref(0)
      const snowflakes = ref([])
      const totalSupply = ref(0)
      const isRefreshing = ref(false)

      return { accountBalance, snowflakes, totalSupply, currentPage, totalPages, isRefreshing };
    },

    async created() {
      this.$emitter.on('wallet-connect', this.refreshData)
      this.$emitter.on('wallet-disconnect', this.clearData)
      this.$emitter.on('refresh-data', this.refreshData)
      this.$emitter.on('thought-update', async (tokenId)=>{
        let snowflake = this.snowflakes.find(n=>n.data.tokenId==tokenId)
        if(snowflake!=null) snowflake.data.thought = await this.$wallet.methods.getThought(tokenId)
      })
    },

    async mounted() {
      await this.refreshData();
    },
    
    async beforeUnmount() {
      this.$emitter.off('wallet-connect')
      this.$emitter.off('wallet-disconnect')
      this.$emitter.off('refresh-data')
      this.$emitter.off('thought-update')
    },

    methods: {
      
      async clearData() {
        this.currentPage = 0
        this.totalPages = 0
        this.accountBalance = 0
        this.snowflakes.length = 0
        this.isRefreshing = false
      },

      async refreshData() {
        if(this.$wallet.state.currentChain==null || this.isRefreshing==true) return;
        this.isRefreshing = true
        
        let c = this.$wallet.state.currentChain.contracts.SnowflakeCollection.exec
        this.accountBalance = parseInt((await c.balanceOf(this.$wallet.state.address)).toString())
        this.totalSupply = parseInt(await c.totalSupply())

        // Get tokenIDs
        let startIndex = this.currentPage * PER_PAGE
        let endIndex = Math.min(this.accountBalance,startIndex+PER_PAGE)
        this.totalPages = Math.ceil(this.accountBalance/PER_PAGE)
        if(this.currentPage >= this.totalPages) this.currentPage = this.totalPages - 1
        let arr = []
        for(let i=startIndex;i<endIndex;i++) arr.push({index:this.accountBalance-i-1,data:null})
        this.snowflakes = arr

        // Get snowflake and owner later
        for(let snowflake of this.snowflakes) {
          c.tokenOfOwnerByIndex(this.$wallet.state.address,snowflake.index).then(tokenId=>{
            this.$wallet.methods.getSnowflake(tokenId).then(data=>{
              snowflake.data = data
              if(data.thought==null) this.$wallet.methods.getThought(tokenId).then(thought=>snowflake.data.thought=thought)
            })
          })
        }

        this.isRefreshing = false
      },

      onRefresh() {
        this.$emitter.emit('notification',{variant:'success',message:"Refresh started."})
        this.clearData()
        this.refreshData()
      },

      onLogout() {
        let cb = () => {
          this.$router.push({name:'Home'})
          this.$wallet.methods.disconnect();
          this.$emitter.emit('notification',{variant:'warning',message:"Wallet disconnected."})
        }
        this.$emitter.emit('confirm-modal',{title:"Disconnect Wallet",variant:"warning",message:"Are you sure you want to disconnect your wallet?",buttonYesTitle:"Disconnect",buttonYesCallback:cb,buttonNoTitle:"Cancel"});
      },

      updatePage(p) {
        this.currentPage = p;
        this.refreshData()
      },

      onEdit(n) {
        this.$emitter.emit('thought-input-modal',{tokenId:n.data.tokenId,thought:n.data.thought})
      },

      imageClicked(n) {
        this.$emitter.emit('image-modal',{src:n})
      }

    },

    computed: {
      
      maxSupply() {
        return this.$wallet.state.maxSupply
      }

    }
  }
</script>
