import BN from 'big.js'

export default {
  
  addressEquals(addr1,addr2) {
    return addr1.toLowerCase()==addr2.toLowerCase()
  },

  shortenAddress(a) {
    if(a==null) return '?';
    a = a.toUpperCase();
    return '0×'+a.substr(2,4)+'…'+a.substr(-4,4);
  },

  shortenAddressHtml(a) {
    if(a==null) return '?';
    a = a.toUpperCase();
    return `<span class="text-gray-400 text-sm">0×</span>${a.substr(2,4)}<span class="text-gray-400 text-sm">…</span>${a.substr(-4,4)}`;
  },

  address(a) {
    if(a==null) return '?';
    a = a.toUpperCase();
    return '0×'+a.substr(2);
  },

  addressHtml(a) {
    if(a==null) return '?';
    a = a.toUpperCase();
    return `<span class="text-gray-400 text-sm">0×</span>${a.substr(2)}`;
  },

  numberCommas(x) {
    if(x==null) return '?';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  numberCommasHtml(x) {
    let str = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);
    return str.replaceAll(",",'<span class="text-gray-400">,</span>');
  },

  formatBalance(b) {
    if(b==null) return "0";

    let n;
    if(b.gt(BN(1000000))) n = b.toFixed(0);
    else if(b.gt(BN(100000))) n = b.toFixed(1);
    else if(b.gt(BN(10000))) n = b.toFixed(2);
    else if(b.gt(BN(1000))) n = b.toFixed(3);
    else if(b.gt(BN(100))) n = b.toFixed(4);
    else if(b.gt(BN(100))) n = b.toFixed(5);
    else if(b.gt(BN(10))) n = b.toFixed(6);
    else if(b.gt(BN(1))) n = b.toFixed(7);
    else if(b.gt(BN(0.1))) n = b.toFixed(8);
    else if(b.gt(BN(0.01))) n = b.toFixed(9);
    else if(b.gt(BN(0.001))) n = b.toFixed(10);
    else if(b.gt(BN(0.001))) n = b.toFixed(11);
    else if(b.gt(BN(0.0001))) n = b.toFixed(12);
    else if(b.gt(BN(0.0001))) n = b.toFixed(13);
    else if(b.gt(BN(0.00001))) n = b.toFixed(14);
    else if(b.gt(BN(0.000001))) n = b.toFixed(15);
    else if(b.gt(BN(0.0000001))) n = b.toFixed(16);
    else if(b.gt(BN(0.00000001))) n = b.toFixed(17);
    else n = b.toFixed(18);

    return n.replace(/\.?0+$/,"");
  },
  
  formatBalanceHtml(b) {
    if(b==null) return "<span class='text-gray-400'>N/A</span>";
    else if(b.eq(0)) return "<span class='text-gray-400'>0</span>";

    if(b.lt(1)) { // Less than 1, make inital '0.' gray
      let arr = this.formatBalance(b).split('.');
      if(arr.length==2) return `<span class="text-gray-400 text-sm">0.</span>${arr[1]}`;
      else return arr[0];
    }
    else { // Greater than 1, make all past decimal gray
      let arr = this.formatBalance(b).split('.');
      if(arr.length==2) return `${arr[0]}<span class="text-gray-400 text-sm">.${arr[1]}</span>`;
      else return arr[0];
    }
  },

  formatBalanceFixed(b, decimals) {
    if(b==null) return "0";
    return b.toFixed(decimals).replace(/\.?0+$/,""); // Remove trailing zeroes
  },
  
  formatCurrencyHtml(n) {
    if(n==null||n.eq(0)) return "<span class='text-gray-400'>N/A</span>";
    else if(n.lt(0.01)) return "<span class='text-gray-400 text-sm'>&lt;$0.</span>01";
    else if(n.gt(1)) {
      let arr = n.toFixed(2).split('.');
      return `<span class="text-gray-400 text-sm">$</span>${this.numberCommasHtml(arr[0])}<span class="text-gray-400 text-sm">.${arr[1]}</span>`;
    }
    else {
      let arr = n.toFixed(2).split('.');
      return `<span class="text-gray-400 text-sm">$0.</span>${arr[1]}`;
    }
  },

  formatChanceHtml(n,total) {
    if(n==null || n==0) return `<span class="text-gray-400">0<span class="text-sm">%</span></span>`;
    else if(n.eq(total)) return `100<span class="text-sm">%</span>`;

    let pct = n.div(total).mul(100).toFixed(0);
    if(pct=="100") return `<span class="text-gray-400 text-sm">&gt;</span>99<span class="text-sm">%</span>`;
    else if(pct>=1) return `${pct}<span class='text-gray-400 text-sm'>%</span>`;
    return `<span class='text-gray-400 text-sm'>&lt;</span>1<span class='text-gray-400 text-sm'>%</span>`;
  },

  formatDateHtml(d) {
    if(d==null) return `<span class="text-gray-400">N/A</span>`;
    let a = d.format('M/D/YYYY').replaceAll('/',`<span class="text-gray-400 text-sm">/</span>`);
    return `${a} <span class="text-gray-400 text-sm">${d.format('h:mm A')}</span>`;
  }

}
