import { createI18n } from "../node_modules/vue3-i18n/dist/vue3-i18n";

/*
HOW TO USE:
this.$t("connection_error_title")
*/

export default createI18n({
  locale: 'en', //navigator.language
  messages: {
    en: {
      chain_not_supported_error: "Chain not supported",
      connect_your_wallet_button: "Connect Your Wallet"
    },
    es: {
      chain_not_supported_error: "Chain not supported",
      connect_your_wallet_button: "Connect Your Wallet"
    }
  }
});
