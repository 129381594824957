import $ from 'jquery'

//Reload whole page on 1 minute of inactivity
export default {

  data() {
    return {
      idleReloadSeconds: 1,
      idleReloadCount: 0,
      idleReloadTime: null,
      idleReloadHandle: null,
      idleReloadCallback: null
    }
  },

  unmounted() {
    //console.log("unmounted")
    this.idleReloadStop();
  },

  methods: {

    idleReloadReset(e) {
      this.idleReloadTime = new Date().getTime();
      //console.log("idleReloadListener = "+this.idleReloadTime);
    },

    idleReloadStart(secs,callback) {
      if(secs!=null) this.idleReloadSeconds = secs;
      if(callback!=null) this.idleReloadCallback = callback;

      //console.log("idleReloadStart");
      this.idleReloadStop();
      function refresh() {
        if(this.idleReloadHandle!=null) clearTimeout(this.idleReloadHandle);
        const diff = (new Date().getTime()-this.idleReloadTime);
        const msecs = this.idleReloadSeconds * 1000;
        //console.log("refresh = "+diff+" "+msecs);
        if(diff>=msecs) {
          this.idleReloadCount++;
          this.idleReloadStop();
          if(this.idleReloadCallback==null) window.location.reload(true);
          else this.idleReloadCallback();
        }
        else this.idleReloadHandle = setTimeout(refresh.bind(this), 10*1000);
      }

      this.idleReloadTime = new Date().getTime();
      this.idleReloadHandle = setTimeout(refresh.bind(this), 10*1000);
      $(document.body).bind("mousemove keypress",this.idleReloadReset.bind(this));
    },

    idleReloadStop() {
      if(this.idleReloadHandle!=null) {
        clearTimeout(this.idleReloadHandle);
        this.idleReloadHandle=null;
      }
      $(document.body).unbind("mousemove keypress",this.idleReloadListener);
    }
    
  }

}
