<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show" @click="show=false" class="cursor-pointer max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              
              <!-- ICON -->
              <div v-if="variant=='success'" class="flex-shrink-0">
                <i class="fa fa-check text-green-500 h-6 w-6"/>
              </div>
              <div v-if="variant=='info'" class="flex-shrink-0">
                <i class="fa fa-check text-blue-700 h-6 w-6"/>
              </div>
              <div v-if="variant=='danger'" class="flex-shrink-0">
                <i class="fa fa-exclamation text-red-400 h-6 w-6"/>
              </div>
              <div v-if="variant=='warning'" class="flex-shrink-0">
                <i class="fa fa-exclamation text-yellow-400 h-6 w-6"/>
              </div>
              <!-- ICON -->

              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{message}}
                </p>
              </div>

              <div class="ml-4 flex-shrink-0 flex">
                <button class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <i class="fa fa-times"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

const DURATION_SECONDS = 5
let timerHandle = 0

export default {
  setup() {
    const show = ref(false)
    const variant = ref(null)
    const message = ref(null)
    
    return {
      show,
      variant,
      message
    }
  },

	mounted() {
		this.$emitter.on('notification', params => {
      if(timerHandle!=null) clearTimeout(timerHandle)
      
			this.show = true
      this.variant = params.variant
			this.message = params.message

      function close() {
        timerHandle = null
        this.show = false;
      }

      timerHandle = setTimeout(close.bind(this), DURATION_SECONDS*1000);
		})
	}

}
</script>