<template>
	<div>
		
		<!--ABOUT-->
		<div class="container md:px-4 mx-auto mt-12 mb-6">
			<div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">

				<div class="flex justify-between">
					<div class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">About Us</div>
					<div>
						<a :href="$store.state.TWITTER_URL" target="_blank">
							<button class="font-bold text-gray-400 text-sm mr-2">
									<div class="flex items-center justify-center">
										<span>TWITTER</span> 
										<i class="fab fa-twitter ml-1"/>
									</div>
							</button>
						</a>
						<!--
						<a :href="$store.state.DISCORD_URL" target="_blank">
							<button class="font-bold text-gray-400 text-sm mr-4">
								<div class="flex items-center justify-center">
									<span>DISCORD</span> 
									<i class="fab fa-discord ml-1"/>
								</div>
							</button>
						</a>
						-->
						<a :href="$store.state.OPENSEA_URL" target="_blank">
							<button class="font-bold text-gray-400 text-sm mr-2">
								<div class="flex items-center justify-center">
									<span>OPENSEA</span> 
									<i class="fa fa-external-link-alt ml-1"/>
								</div>
							</button>
						</a>
						<a :href="$store.state.RARIBLE_URL" target="_blank">
							<button class="font-bold text-gray-400 text-sm">
								<div class="flex items-center justify-center">
									<span>RARIBLE</span> 
									<i class="fa fa-external-link-alt ml-1"/>
								</div>
							</button>
						</a>
					</div>
				</div>

				<div class="text-base mb-2">
					<p class="mb-3">
						We are a team of blockchain developers, artists and designers dedicated to making imaginative and thought-provoking projects using the latest technological innovations.
						Please contact us about any interesting opportunities or partnerships.
						We are focused on NFT and DApps and are super excited to release more projects!  
						We believe that crypto and blockchains are the future of humanity and will forever change society.
					</p>
					<p class="mb-3">
						Our app "Snowflake Thoughts" exclusive decentralized public messaging is just a taste of what's to come!  
						We have reasonable limits to what can be posted, and reserve the right to remove messages for any reason.
						Those messages still can be accessed directly from the contract by other apps.
						We will follow standard FCC guidelines and remove such topics as violence, nudity, doxxing, scams etc. 
						In general, please consider yourselves anonymous when posting.
						Feel free to post marketing materials, such as the projects you want to get some attention.
					</p>
				</div>
				<a :href="$store.state.DISCORD_URL" target="_blank" class="block mt-8">
					<button class="font-bold bg-blue-600 hover:bg-blue-500 border-2 border-blue-700 text-white text-lg px-3 py-1 rounded-full">
						<div class="flex items-center justify-center">
							<span>JOIN OUR DISCORD</span> 
							<i class="fab fa-discord ml-2"/>
						</div>
					</button>
				</a>
				<a :href="'mailto:'+$store.state.CONTACT_EMAIL" class="block mt-4">
					<button class="font-bold bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white px-3 py-1 rounded-full">
						<div class="flex items-center justify-center">
							<span>CONTACT US</span> 
							<i class="fa fa-envelope ml-2"/>
						</div>
					</button>
				</a>
		</div>
		</div>
		<!--ABOUT-->

		<!--CONTRACT-->
		<div class="container md:px-4 mx-auto mt-6 mb-0">
			<div class="p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
				<div class="flex justify-between">
					<div class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Contract Information</div>
				</div>
				<div class="text-base mb-2">
					The entire Snowflake artwork and Thoughts data is 100% generated on-chain by our amazing dev team.
					<br/>

					<div v-if="$wallet.state.currentChain!=null">
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2 flex items-center">
								<span>Network</span> 
								<img :src="$wallet.state.currentChain.image" class="ml-1 h-5 min-w-max rounded-full"/>
							</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{$wallet.state.currentChain.name}} - {{$wallet.state.chainId}}</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2 space-x-1 flex items-center">
								<span>Collection ERC721</span>
								<div v-if="$wallet.methods.isConnected()" class="inline-block cursor-pointer" @click="$wallet.methods.suggestToken()">
									<img src="https://static.coingecko.com/s/metamask_fox-11b1aab7f9a07cbe8903d8d6eb1e6d42be66d1bdd838c10786c1c49a2efb36f0.svg" class="h-5 min-w-max"/>
								</div>
							</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
								<span class="font-mono overflow-hidden">{{$wallet.state.currentChain.contracts.SnowflakeCollection.address}}</span>
								<a target="_blank" :href="$wallet.state.currentChain.etherscan_address_url+$wallet.state.currentChain.contracts.SnowflakeCollection.address" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">etherscan</a>
							</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">Free Minting</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{freeMintCount}} / {{freeMintTotal}}</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">Free Per Address</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{freeMintPerAddress}}</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">Mint Cost</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full font-mono">{{$common.formatBalance($BN(cost).div(10**18))}} ETH</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">Total Supply</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
								<div class="font-mono">{{totalSupply}} / {{maxSupply}}</div>
							</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">Thoughts Contract</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
								<span class="font-mono overflow-hidden">{{$wallet.state.currentChain.contracts.SnowflakeThoughts.address}}</span>
								<a target="_blank" :href="$wallet.state.currentChain.etherscan_address_url+$wallet.state.currentChain.contracts.SnowflakeThoughts.address" class="text-sm px-2 py-0 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white rounded-full ml-1">etherscan</a>
							</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">Total Thoughts</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
								<div class="font-mono">{{messageCount}}</div>
							</div>
						</div>
						<div class="lg:flex mt-6">
							<div class="w-full lg:w-1/4 px-3 py-1 bg-white bg-opacity-10 rounded-full lg:mr-2">Max Thought Length</div>
							<div class="block w-full lg:w-3/4 mt-2 lg:mt-0 px-3 py-1 bg-black bg-opacity-10 rounded-full flex justify-between">
								<div class="font-mono">{{maxMessageLength}}</div>
							</div>
						</div>
					</div>
					
					<div v-if="$wallet.state.currentChain==null" class="px-3 py-2 bg-black bg-opacity-10 rounded-full mt-8 text-center">
						Connect Your Wallet
					</div>
				</div>
			</div>
		</div>
		<!--CONTRACT-->


	</div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import { ethers } from "ethers"
  import IdleReloadMixin from "../mixins/IdleReloadMixin"

  const REFRESH_SECS = 10;

  export default {

    mixins: [IdleReloadMixin],

    setup() {
      const snowflakeGenerator = ref(null)
      const cost = ref(0)
      const maxSupply = ref(0)
      const totalSupply = ref(0)
      const messageCount = ref(0)
      const freeMintTotal = ref(0)
      const freeMintCount = ref(0)
      const freeMintPerAddress = ref(0)
      const paidMintCount = ref(0)
      const maxMessageLength = ref(0)

      return { 
        cost, 
        maxSupply, 
        totalSupply, 
        messageCount, 
        freeMintTotal, 
        freeMintCount, 
        paidMintCount, 
        maxMessageLength,
        freeMintPerAddress
      };
    },

    async created() {
      this.$emitter.on('wallet-connect', this.refreshData)
      this.$emitter.on('wallet-disconnect', this.clearData)
      this.$emitter.on('refresh-data', this.refreshData)
    },

    async mounted() {
      await this.idleStart();
    },
    
    async beforeUnmount() {
      this.$emitter.off('wallet-connect')
      this.$emitter.off('wallet-disconnect')
      this.$emitter.off('refresh-data')
    },

    methods: {

      async idleStart() {
        await this.refreshData();
        this.idleReloadStart(REFRESH_SECS,this.idleStart)
      },


      async clearData() {
        this.snowflakeGenerator = null
      },

      async refreshData() {
        if(this.$wallet.state.currentChain==null || this.isRefreshing==true) return;
        this.isRefreshing = true

        let p = new ethers.providers.Web3Provider(this.$wallet.state.provider)

        let collection = this.$wallet.state.currentChain.contracts.SnowflakeCollection.exec
        this.cost = this.$BN(await collection.cost())
        this.maxSupply = parseInt(await collection.maxSupply())
        this.totalSupply = parseInt(await collection.totalSupply())
        this.freeMintTotal = await collection.freeMintTotal()
        this.freeMintCount = await collection.freeMintCount()
        this.paidMintCount = await collection.paidMintCount()
        this.freeMintPerAddress =  await collection.freeMintPerAddress()

        let thoughts = this.$wallet.state.currentChain.contracts.SnowflakeThoughts.exec
        this.messageCount = await thoughts.messageCount()
        this.maxMessageLength = this.maxMessageLengthParams = await thoughts.maxMessageLength()
        
        this.isRefreshing = false
      },

    }

  }
</script>
