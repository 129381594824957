<template>
	<div>

		<!--Main-->
		<div class="container py-6 md:py-12 md:px-4 mx-auto flex flex-wrap flex-col md:flex-row items-stretch">

			<!--Left Col-->
			<div class="w-full lg:w-2/5 mb-6 xl:mb-0">
				<div class="lg:mr-4 p-6 md:p-10 shadow-xl sm:rounded-lg border-t-4 border-b-4 sm:border-4 card-bg">
					<div class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Mint Snowflake</div>
					<div class="text-base">
						<span v-if="totalSupply<maxSupply && $BN(mintCost).gt(0)">Mint your snowflake for {{$common.formatBalance($BN(mintCost).div(10**18))}} ETH.</span>
						Each snowflake is unique and one of a kind just like you!  
						When you mint, you will receive the next available snowflake.
						You can also trade your snowflakes on <a :href="$store.state.OPENSEA_URL" target="_blank" class="underline">OpenSea</a> or <a :href="$store.state.RARIBLE_URL" target="_blank" class="underline">Rarible</a>.  
						Ownership gives you exclusive access to our <router-link :to="{name:'Thoughts'}" class="underline">Club</router-link> for decentralized public message board!
					</div>

					<!-- MINTING COMPLETE -->
					<button v-if="totalSupply==maxSupply" :disabled="true" class="font-bold w-full mt-8 bg-gray-500 hover:bg-gray-400 border-2 border-gray-600 text-white px-3 py-1 rounded-full text-lg focus:outline-none focus:ring focus:border-indigo-300">
						MINTING COMPLETE
					</button>
					<!-- MINTING COMPLETE -->

					<!-- MINTING PAUSED -->
					<button v-else-if="mintPaused" :disabled="true" class="font-bold w-full mt-8 bg-gray-500 hover:bg-gray-400 border-2 border-gray-600 text-white px-3 py-1 rounded-full text-lg focus:outline-none focus:ring focus:border-indigo-300">
						MINT COMING SOON
					</button>
					<!-- MINTING PAUSED -->

					<!-- FREE MINT -->
					<button v-else-if="canFreeMint" @click="onMint" :class="mintRunning?'bg-gray-500 hover:bg-gray-400 border-gray-600':'bg-indigo-500 hover:bg-indigo-400 border-indigo-600'" class="font-bold w-full mt-8 border-2 text-white px-3 py-1 rounded-full text-lg focus:outline-none focus:ring focus:border-indigo-300">
						<div class="flex items-center justify-center">
							<span>MINT SNOWFLAKE</span>
							<span class="bg-black bg-opacity-20 px-2 py-1 ml-1 rounded-full text-sm">FREE</span>
						</div>
					</button>
					<!-- FREE MINT -->

					<!-- PAID MINT -->
					<button v-else-if="!canFreeMint && $BN(mintCost).gt(0)" @click="onMint" :class="mintRunning?'bg-gray-500 hover:bg-gray-400 border-gray-600':'bg-indigo-500 hover:bg-indigo-400 border-indigo-600'" class="font-bold w-full mt-8 border-2 text-white px-3 py-1 rounded-full text-lg focus:outline-none focus:ring focus:border-indigo-300">
						<div class="flex items-center justify-center">
							<span>MINT SNOWFLAKE</span> 
							<span class="bg-black bg-opacity-20 px-2 py-1 ml-2 rounded-full text-sm">{{$common.formatBalance($BN(mintCost).div(10**18))}} ETH</span>
						</div>
					</button>
					<!-- PAID MINT -->

					<!-- GRAY MINT -->
					<button v-else @click="onMint" class="font-bold w-full mt-8 bg-gray-500 hover:bg-gray-400 border-2 border-gray-600 text-white px-3 py-1 rounded-full text-lg focus:outline-none focus:ring focus:border-indigo-300">
						MINT SNOWFLAKE
					</button>
					<!-- GRAY MINT -->

					<a :href="this.$store.state.OPENSEA_URL" target="_blank"><button class="font-bold w-full mt-4 bg-indigo-600 hover:bg-indigo-500 border-2 border-indigo-700 text-white px-3 py-1 rounded-full text-lg">TRADE ON OPENSEA</button></a>

					<a :href="$store.state.DISCORD_URL" target="_blank">
						<button class="mt-4 w-full font-bold bg-blue-600 hover:bg-blue-500 border-2 border-blue-700 text-white text-lg px-3 py-1 rounded-full">
							<div class="flex items-center justify-center">
								<span>JOIN OUR DISCORD</span> 
								<i class="fab fa-discord ml-2"/>
							</div>
						</button>
					</a>


					<div v-if="$wallet.methods.isConnected()==false" class="text-center text-gray-400 text-sm mt-4">connect your wallet</div>
					<div v-else-if="totalSupply<maxSupply && freeMintCount<freeMintTotal" class="text-center text-gray-400 text-sm mt-4">{{freeMintTotal-freeMintCount}} free mints left</div>
					<div v-else-if="totalSupply<maxSupply" class="text-center text-gray-400 text-sm mt-4">no more free mints</div>
				</div>
			</div>
			<!--Left Col-->

			<!--Right Col-->
			<div class="w-full lg:w-3/5 mb-4 xl:mb-0">
				<div class="lg:ml-4 p-6 md:p-10 shadow-xl sm:rounded-lg h-full border-t-4 border-b-4 sm:border-4 card-bg">
					
					<!-- Snowflake SVG -->
					<img @click="imageClicked(currentFlakeSvg)" class="rounded-xl border-4 border-black border-opacity-20 cursor-pointer" :src="currentFlakeSvg==null?'/images/snowflake-213.svg':currentFlakeSvg"/>
					<!-- Snowflake SVG -->

					<div class="flex justify-between mt-6">

						<!--BACK-->
						<div class="items-center">
							<div class="w-full">
								<button @click="onFirst" :disabled="currentFlakeIndex==0" :class="currentFlakeIndex==0?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-2 py-1 rounded-full text-sm mr-1 border-2 focus:outline-none focus:ring focus:border-indigo-300">
									<div class="flex items-center">
										<i class="fa fa-caret-left text-lg"/><i class="fa fa-caret-left text-lg"/>
									</div>
								</button>
								<button @click="onBack" :disabled="currentFlakeIndex==0" :class="currentFlakeIndex==0?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-3 py-1 rounded-full text-sm border-2 focus:outline-none focus:ring focus:border-indigo-300">
									<div class="flex items-center">
										<i class="fa fa-caret-left text-lg mr-1"/>
										<span>BACK</span>
									</div>
								</button>
							</div>
						</div>
						<!--BACK-->

						<!--SNOWFLAKE PAGINATE-->
						<div class="flex flex-col text-center">
							<div class="text-gray-100 text-md md:text-xl">
								<b>Snowflake</b> <span v-if="currentFlakeTokenId>0" class="font-mono text-gray-300">#{{currentFlakeTokenId}}</span>
							</div>
							<div class="text-gray-400 text-sm">
								<span v-if="$wallet.state.currentChain==null">connect your wallet</span>
								<span v-else-if="snowflakeLoading">&hellip; loading &hellip;</span>
								<span v-else-if="totalSupply==0">none yet</span>
								<span v-else>{{currentFlakeIndex+1}} of {{totalSupply}}</span>
							</div>
						</div>
						<!--SNOWFLAKE PAGINATE-->

						<!--NEXT-->
						<div class="items-center">
							<div class="w-full flex">
								<button @click="onNext" :disabled="(totalSupply==0 || currentFlakeIndex==totalSupply-1)" :class="(totalSupply==0 || currentFlakeIndex==totalSupply-1)?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-3 py-1 rounded-full text-sm mr-1 border-2 focus:outline-none focus:ring focus:border-indigo-300">
									<div class="flex items-center">
										<span>NEXT</span>
										<i class="fa fa-caret-right text-lg ml-1"/>
									</div>
								</button>
							<button @click="onLast" :disabled="(totalSupply==0 || currentFlakeIndex==totalSupply-1)" :class="(totalSupply==0 || currentFlakeIndex==totalSupply-1)?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-2 py-1 rounded-full text-sm border-2 focus:outline-none focus:ring focus:border-indigo-300">
									<div class="flex items-center">
										<i class="fa fa-caret-right text-lg"/><i class="fa fa-caret-right text-lg"/>
									</div>
								</button>
							</div>
						</div>
						<!--NEXT-->

					</div>
				</div>
			</div>
			<!--Right Col-->

		</div>

		<!--Rarities Section-->
		<div class="p-10 h-full shadow-xl border-t-4 border-b-4 card-bg">
			<div class="w-full container mx-auto p-0 md:p-4">
				<div class="w-full text-md">
					<h1 class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Snowflake Rarities</h1>
					<div class="text-base">
						Each amazingly beautiful snowflake is generated directly on-chain with animation and no external files.
						With all the technical limitations, it is an amazing feat on the blockchain!
						We separated the snowflakes into unique 3 sets.  
						From 1 - 9985 are the <b>Wisdom Snowflakes</b> that give their owners eternal wisdom and strength.
						The <b>Revolution Snowflakes</b> are a special set from the range of 9986-9999 which represent the passion and determination of their owners.
						Lastly, the <b>Divinity Snowflake</b> is number 10,000 which will grant the owner good fortune and many blessings in life.
						Each tier will give the owner rights in our exclusive <a href="#" class="underline">"Thoughts"</a> message wall with the highest token number sorted first.
					</div>
					<div class="flex mt-5">
						<div class="flex-1 mr-2">
							<img class="rounded-xl border-4 border-black border-opacity-20" src="images/snowflake-2439.svg"/>
							<div class="text-center font-bold mt-4 bg-black bg-opacity-10 py-2 px-3 rounded-full">Wisdom<span class="hidden md:inline"> 1 - 9985</span></div>
						</div>
						<div class="flex-1 mx-1">
							<img class="rounded-xl border-4 border-black border-opacity-20" src="images/snowflake-9994.svg"/>
							<div class="text-center font-bold mt-4 bg-black bg-opacity-10 py-2 px-3 rounded-full">Revolution<span class="hidden md:inline"> 9986 - 9999</span></div>
						</div>
						<div class="flex-1 ml-2">
							<img class="rounded-xl border-4 border-black border-opacity-20" src="images/snowflake-10000.svg"/>
							<div class="text-center font-bold mt-4 bg-black bg-opacity-10 py-2 px-3 rounded-full">Divinity<span class="hidden md:inline">&nbsp;{{maxSupply}}</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--Rarities Section-->

		<!--Thoughts Section-->
		<div class="p-10 h-full shadow-xl mt-12 border-t-4 border-b-4 card-bg">
			<div class="w-full container mx-auto p-0 md:p-4">
				<div class="w-full text-md">
					<h1 class="font-bold text-gray-100 text-2xl md:text-3xl mb-4">Snowflake Club</h1>
						Owning a snowflake give you the exclusive right to express your thoughts censhorship-free on the blockchain.
						We do adhere to banning messages that are against standard FCC rules, also any obvious scams.
						But, all your messages will be carved forever and readable by the contract. 
						It is sorted by the rarity of your snowflake and the date.
						You can also use it to promote your own projects.
						<div v-if="thoughts.length>0" class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-5 mt-6">
							<div v-for="(n,i) of thoughts" v-bind:key="n.index">
								<thought-view :n="n" :i="i" :message-count="messageCount"/>
							</div>
						</div>
						<div class="mt-2">
							<router-link :to="{name:'Thoughts'}">
								<button class="mt-8 font-bold bg-indigo-500 hover:bg-indigo-400 text-white px-3 py-1 rounded-full text-md mr-2 border-2 border-indigo-600">VISIT THOUGHTS</button>
							</router-link>
						</div>
				</div>
			</div>
		</div>
		<!--Thought Section-->

		<!--Roadmap Section-->
		<div class="p-10 h-full shadow-xl mt-12 border-t-4 border-b-4 card-bg">
			<div class="w-full container mx-auto p-0 md:p-4">
				<div class="w-full text-md">
					
					<div class="flex justify-between mb-4">
						<h1 class="font-bold text-gray-100 text-2xl md:text-3xl">Snowflake Roadmap</h1>
						<div v-if="totalSupply>0" class="flex items-center">
							<div class="bg-black bg-opacity-10 py-1 ml-1 px-3 rounded-full text-sm">{{totalSupply}} Owned ({{((totalSupply/maxSupply)*100).toFixed(2)}}%)</div>
						</div>
					</div>

					<div class="mb-6">
						We set goals based on percent of snowflakes that have been minted.  
						There will be 300 snowflakes reserved for airdrop giveaways and marketing.
						Also 1000 snowflakes will be free to claim, 1 per address.
						The next 1001-9985 will be pay to claim priced starting at {{$common.formatBalance($BN(mintCost).div(10**18))}} ETH and increases with demand.
						The final 9001-{{maxSupply}} will be available on <a :href="$store.state.OPENSEA_URL">OpenSea</a> or <a :href="$store.state.RARIBLE_URL">Rarible</a> for purchase and trading.
						At certain percentages of total ownership we will start airdrops, giveaways, and gradual floor prices increases.
						Winners will be announced right here, stay tuned!
					</div>
					<div class="mt-4">
						<div v-for="n of ROADMAPS" :key="n.pct" class="mt-2 flex">
							<div :class="(totalSupply>0 && ((totalSupply/maxSupply)*100)>=n.pct)?'bg-yellow-200 bg-opacity-30':'bg-gray-400 bg-opacity-10'" class="py-2 px-4 my-1 rounded-l-lg text-lg text-bold" style="width:70px">{{n.pct}}%</div>
							<div :class="(totalSupply>0 && ((totalSupply/maxSupply)*100)>=n.pct)?'bg-black bg-opacity-30':'bg-black bg-opacity-10'" class="flex-1 py-2 px-4 my-1 rounded-r-lg text-lg">{{n.msg}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--Roadmap Section-->

	</div>
</template>


<style scoped>
</style>


<script>
  import { ref } from 'vue'
  import { ethers } from 'ethers'
  import { useRoute } from 'vue-router'
	import ThoughtView from '../components/ThoughtView.vue'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'

  const REFRESH_SECS = 10;

  export default {

		components: {ThoughtView},

    mixins: [IdleReloadMixin],
    
    setup() {
			const mintPaused = ref(false)
      const mintRunning = ref(false)
      const totalSupply = ref(0)
      const canFreeMint = ref(false)
      const freeMintCount = ref(0)
      const freeMintTotal = ref(0)
      const mintCost = ref(0)
      const currentFlakeIndex = ref(0)
      const currentFlakeTokenId = ref(0)
      const currentFlakeSvg = ref(null)
      const snowflakeLoading = ref(true)
      const mintHash = ref(null)
			const thoughts = ref([])
			const messageCount = ref(0)

      const ROADMAPS = [
        {pct:0,msg:"300 free to mint Wisdom Snowflakes."},
        {pct:20,msg:"1 Wisdom Snowflake to 1 random owner."},
        {pct:40,msg:"1 Wisdom Snowflakes to 1 random owner."},
        {pct:60,msg:"1 Wisdom Snowflakes to 1 random owner."},
        {pct:80,msg:"1 Wisdom Snowflakes to 2 random owners."},
        {pct:100,msg:"1.0 Ethereum sent to 3 random owners.  We did it!"},
      ]

      return { 
        ROADMAPS, 
        totalSupply, 
        canFreeMint, 
        mintCost, 
        freeMintCount,
        freeMintTotal, 
				mintPaused,
        mintRunning, 
        currentFlakeIndex, 
        currentFlakeSvg,
        currentFlakeTokenId,
        snowflakeLoading,
        mintHash,
				thoughts,
				messageCount
      };
    },

    async created() {
      this.$emitter.on('wallet-connect', this.refreshData)
      this.$emitter.on('wallet-disconnect', this.clearData)
      this.$emitter.on('refresh-data', this.refreshData)
			this.$emitter.on('thought-update', async (tokenId)=>{
        let thought = this.thoughts.find(n=>n.data.tokenId==tokenId)
        if(thought!=null) {
          let updatedThought = await this.$wallet.methods.getThought(tokenId)
          thought.data.date = updatedThought.date
          thought.data.message = updatedThought.message
          
          // Sort all thoughts
          this.thoughts = this.thoughts.filter(n=>n.data.ban==false).sort((a,b)=>b.data.rarity-a.data.rarity || b.data.date.diff(a.data.date))
        }
      })
    },

    async mounted() {
      const route = useRoute()
      this.currentFlakeIndex = Math.min(0,parseInt(route.query.i || 0))

      this.idleStart()
    },
    
    async beforeUnmount() {
      this.$emitter.off('wallet-connect')
      this.$emitter.off('wallet-disconnect')
      this.$emitter.off('refresh-data')
      this.$emitter.off('thought-update')
    },

    methods: {

      async idleStart() {
        await this.refreshData();
        this.idleReloadStart(REFRESH_SECS,this.idleStart)
      },

      async clearData() {
        this.currentFlakeTokenId = 0
        this.totalSupply = 0
        this.canFreeMint = false
        this.freeMintCount = 0
        this.freeMintTotal = 0
        this.mintCost = 0
				this.mintPaused = false
      },

      async refreshData() {
        if(this.$wallet.state.currentChain==null) return
        
        //console.log("refreshData")
        this.idleReloadReset()
        let c = this.$wallet.state.currentChain.contracts.SnowflakeCollection.exec
        this.totalSupply = parseInt(await c.totalSupply())
        this.freeMintCount = await c.freeMintCount()
        this.freeMintTotal = await c.freeMintTotal()
				this.mintPaused = await c.paused()
        this.canFreeMint = await c.canFreeMint({from:this.$wallet.state.address})
        this.mintCost = await c.cost()

				// Get thoughts
				let t = this.$wallet.state.currentChain.contracts.SnowflakeThoughts.exec
        this.messageCount = parseInt(await t.messageCount())
        let arr = []
        for(let i=0;i<this.messageCount;i++) arr.push({index:i,data:null})
        this.thoughts = arr;
        for(let n of this.thoughts) {
          await this.$wallet.methods.getThoughtByIndex(n.index).then(thought=>{
            n.data = thought
            if(this.thoughts.filter(m=>m.data!=null).length==this.thoughts.length) this.loadSnowflakes() // If the last thought loaded, we can no sort and get all images
          })
        }

				// Get snowflakes
        if(this.totalSupply>0) {
          if(this.currentFlakeIndex>=this.totalSupply) this.currentFlakeIndex = this.totalSupply-1 // Request index is over
          if(this.currentFlakeSvg==null) this.refreshSnowflake()
        }
        else {
          this.snowflakeLoading = 0
        }

        //console.log(this.currentFlakeIndex)
      },

      async refreshSnowflake() {
        this.snowflakeLoading = true
        let c = this.$wallet.state.currentChain.contracts.SnowflakeCollection.exec
        this.currentFlakeTokenId = await c.tokenByIndex(this.currentFlakeIndex)
        let snowflakeJson = await this.$wallet.methods.getSnowflake(this.currentFlakeTokenId) 
        this.currentFlakeSvg = snowflakeJson.image;
        this.snowflakeLoading = false
      },

		 	loadSnowflakes() {
        // Sort all thoughts
        this.thoughts = this.thoughts.filter(n=>n.data.ban==false && n.data.message.length>0).sort((a,b)=>b.data.rarity-a.data.rarity || b.data.date.diff(a.data.date))
				this.thoughts = this.thoughts.splice(0,1); //1st 2 only

				// Get thought images now
        for(let n of this.thoughts) {
          if(n.data.image==null) this.$wallet.methods.getSnowflakeImage(n.data.tokenId).then(image=>n.data.image=image)
        }
      },

	    async onFirst() {
        if(this.currentFlakeIndex<=0) return
        this.currentFlakeIndex=0
        await this.refreshSnowflake()
        this.idleReloadReset()
      },

      async onBack() {
        if(this.currentFlakeIndex<=0) return
        this.currentFlakeIndex--
        await this.refreshSnowflake()
        this.idleReloadReset()
      },

      async onNext() {
        if(this.totalSupply==0 || this.currentFlakeIndex>=this.totalSupply-1) return
        this.currentFlakeIndex++
        await this.refreshSnowflake()
        this.idleReloadReset()
      },

      async onLast() {
        if(this.totalSupply==0 || this.currentFlakeIndex>=this.totalSupply-1) return
        this.currentFlakeIndex=this.totalSupply-1
        await this.refreshSnowflake()
        this.idleReloadReset()
      },

      async onMint() {
        if(this.mintRunning==true) {
          this.$emitter.emit('ok-modal', {title:"Wallet Error",variant:'danger',message:"Please check your wallet for a pending transaction."})
          return
        }

        // Must be connected
        if(!this.$wallet.methods.isConnected()) {
          this.$emitter.emit('ok-modal',{title:"Connect Wallet",variant:'warning',message:"Connect your wallet on the top right to mint snowflakes."})
          return
        }

        this.mintRunning = true
        this.idleReloadReset()

        // Try to free mint
        try {
          let provider = new ethers.providers.Web3Provider(this.$wallet.state.provider)
          let snowSigned = new ethers.Contract(this.$wallet.state.currentChain.contracts.SnowflakeCollection.address, this.$wallet.state.abis.SnowflakeCollection, provider.getSigner())  
					
          let tx = null
          if(this.canFreeMint == true) {
            tx = await snowSigned.freeMint({from:this.$wallet.state.address})
          }
          else {
            let value = await snowSigned.cost()
            tx = await snowSigned.paidMint({from:this.$wallet.state.address,value})
          }

          this.mintHash = tx.hash
          await snowSigned.on("MintEvent",this.onMintEvent)
          this.$emitter.emit('ok-modal', {title:"Mint Started",variant:'success',message:`<p class="text-lg">Your transaction to mint has started.</p><br/>Check the progress by visiting <a class="underline" target="_blank" href="${this.$wallet.state.currentChain.etherscan_tx_url}${this.mintHash}">Etherscan</a> or wait here until it is complete.  It may take a few minutes to complete.`})
        } 
        catch(err) {
          let message = this.$wallet.methods.xlatWeb3Error(err)
          this.$emitter.emit('ok-modal', {title:"Wallet Error",variant:'danger',message})
          console.log(message)
          this.mintRunning = false
        }
      },

      async onMintEvent(address,tokenId,tx) {
        if(tx.transactionHash!=this.mintHash) return
        this.mintRunning = false
        this.mintHash = null
        this.$emitter.emit('refresh-data') 
        this.$emitter.emit('ok-modal', {title:"Mint Complete",variant:'success',message:`<p class="text-lg">You are now owner of Snowflake #${tokenId.toString()}</p><br/>Remember to visit the <a href="/#/thoughts" class="underline">Club</a> page to express your thoughts to the world for all your Snowflakes.`})
      },
      
      imageClicked(n) {
        if(n!=null) this.$emitter.emit('image-modal',{src:n})
      }
      
    },

    computed: {
      
      maxSupply() {
        return this.$wallet.state.maxSupply
      }

    }
  }
</script>
