<template>

	<!--PAGINATOR-->
	<div class="flex justify-center">

		<!--BACK-->
		<div class="items-center">
			<div class="w-full">
				<button @click="onFirst" :disabled="currentPage==0" :class="currentPage==0?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-2 py-1 rounded-full text-sm mr-1 border-2 focus:outline-none focus:ring focus:border-indigo-300">
					<div class="flex items-center">
						<i class="fa fa-caret-left text-lg"/><i class="fa fa-caret-left text-lg"/>
					</div>
				</button>
				<button @click="onBack" :disabled="currentPage==0" :class="currentPage==0?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-3 py-1 rounded-full text-sm border-2 focus:outline-none focus:ring focus:border-indigo-300">
					<div class="flex items-center">
						<i class="fa fa-caret-left text-lg mr-1"/>
						<span>BACK</span>
					</div>
				</button>
			</div>
		</div>
		<!--BACK-->

		<!--PAGINATE-->
		<div class="items-center flex">
			<div class="text-gray-400 px-3 py-1 bg-black bg-opacity-10 rounded-full mx-3 lg:mx-4">
				PAGE {{currentPage+1}} / {{totalPages}}
			</div>
		</div>
		<!--PAGINATE-->

		<!--NEXT-->
		<div class="items-center">
			<div class="w-full flex">
				<button @click="onNext" :disabled="currentPage==totalPages-1" :class="(currentPage==totalPages-1)?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-3 py-1 rounded-full text-sm mr-1 border-2 focus:outline-none focus:ring focus:border-indigo-300">
					<div class="flex items-center">
						<span>NEXT</span>
						<i class="fa fa-caret-right text-lg ml-1"/>
					</div>
				</button>
				<button @click="onLast" :disabled="currentPage==totalPages-1" :class="(currentPage==totalPages-1)?'bg-gray-500 border-gray-600 cursor-default':'bg-indigo-500 border-indigo-600 hover:bg-indigo-400'" class="font-bold text-white px-2 py-1 rounded-full text-sm border-2 focus:outline-none focus:ring focus:border-indigo-300">
					<div class="flex items-center">
						<i class="fa fa-caret-right text-lg"/><i class="fa fa-caret-right text-lg"/>
					</div>
				</button>
			</div>
		</div>
		<!--NEXT-->

	</div>

</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'

  const PER_PAGE = 10

  export default {

		props: {
			currentPage: Number,
			totalPages: Number
		},
    
    setup() {
    },

    async created() {
    },

    async mounted() {
    },
    
    methods: {
      
      onPage(p) {
				this.$emit('update',{page:p})
      },

      onFirst() {
        if(this.currentPage==0) return
				this.$emit('update',0)
      },

      onBack() {
        if(this.currentPage==0) return
				this.$emit('update',this.currentPage-1)
      },

      onLast() {
        if(this.currentPage==this.totalPages-1) return
				this.$emit('update',this.totalPages-1)
      },

      onNext() {
        if(this.currentPage==this.totalPages-1) return
				this.$emit('update',this.currentPage+1)
      },

    },

    computed: {
    }

  }
</script>
